<template>
  <div>
    <div>
      <el-button
        type="success"
        @click="openAddDialog()"
        style="margin: 0px 10px 10px 10px"
        >添加渠道</el-button
      >
    </div>
    <el-table :data="tableData" border stripe style="width: 100%">
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column prop="id" label="id" width="180"> </el-table-column>
      <el-table-column prop="channel" label="渠道代码"> </el-table-column>
      <el-table-column prop="pixel_code" label="像素代码"> </el-table-column>
      <el-table-column prop="html" label="进入APP事件打点">
        <template slot-scope="scope">
          <span
            style="
              width: 10px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            "
            >{{ scope.row.html }}</span
          >
        </template>
      </el-table-column>
      <el-table-column prop="track_reg" label="注册事件打点">
        <template slot-scope="scope">
          <span
            style="
              width: 10px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            "
            >{{ scope.row.track_reg }}</span
          >
        </template>
      </el-table-column>
      <el-table-column prop="track_first_pay" label="首充打点">
        <template slot-scope="scope">
          <span
            style="
              width: 10px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            "
            >{{ scope.row.track_first_pay }}</span
          >
        </template>
      </el-table-column>
      <el-table-column label="操作" width="170">
        <template slot-scope="scope">
          <el-button type="primary" @click="OpenEditDialog(scope.row)"
            >编辑</el-button
          >
          <el-popconfirm
            title="这是一段内容确定删除吗？"
            @confirm="DeleteAdvertises(scope.row.id)"
          >
            <el-button type="danger" slot="reference" style="margin-left: 10px"
              >删除</el-button
            >
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <!-- 添加dialog -->
    <el-dialog title="添加" :visible.sync="dialogVisible" width="30%">
      <el-form>
        <el-form-item label="渠道">
          <el-input v-model="addChannel" :value="addChannel"></el-input>
        </el-form-item>
        <el-form-item label="track_view">
          <el-input v-model="addTrackView"></el-input>
        </el-form-item>
        <el-form-item label="track_reg">
          <el-input v-model="addTrackReg"></el-input>
        </el-form-item>
        <el-form-item label="track_first_pay">
          <el-input v-model="addTrackFirstPay"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="AddAdvertises()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 编辑dialog -->
    <el-dialog title="编辑" :visible.sync="EditdialogVisible" width="30%">
      <el-button
        type="primary"
        style="margin-left: 10px"
        @click="OpenCodeDialog()"
        >编辑track_view内容(注册事件打点)</el-button
      >
      <el-button
        type="primary"
        style="margin-top: 10px"
        @click="OpenCodeDialog2()"
        >编辑track_pay内容</el-button
      >
      <el-button
        type="primary"
        style="margin-top: 10px"
        @click="OpenCodeDialog1()"
        >编辑track_first_pay内容(首充打点)</el-button
      >
      <p>像素代码：</p>
      <el-input v-model="updatePixelCode" :value="updatePixelCode"></el-input>
      <p>渠道代码：</p>
      <el-input
        v-model="updateChannel"
        :value="updateChannel"
        style="width: 300px"
      ></el-input>
      <el-button type="primary" style="margin-left: 10px" @click="RandomCode()"
        >随机代码</el-button
      >
      <span slot="footer" class="dialog-footer">
        <el-button @click="EditdialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="UpdateAdvertises()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 代码编辑dialog -->
    <el-dialog
      title="代码编辑"
      :visible.sync="CodedialogVisible"
      width="50%"
      @open="onCodeDialogOpen"
    >
      <codemirror
        ref="codeMirror"
        v-model="updateTrackView"
        :options="editorOptions"
      ></codemirror>
      <span slot="footer" class="dialog-footer">
        <el-button @click="CodedialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="CodedialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 代码编辑2dialog -->
    <el-dialog
      title="代码编辑"
      :visible.sync="CodedialogVisible1"
      width="50%"
      @open="onCodeDialogOpen"
    >
      <codemirror
        ref="codeMirror"
        v-model="updateTrackFirstPay"
        :options="editorOptions"
      ></codemirror>
      <span slot="footer" class="dialog-footer">
        <el-button @click="CodedialogVisible1 = false">取 消</el-button>
        <el-button type="primary" @click="CodedialogVisible1 = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 代码编辑3dialog -->
    <el-dialog
      title="代码编辑"
      :visible.sync="CodedialogVisible2"
      width="50%"
      @open="onCodeDialogOpen"
    >
      <codemirror
        ref="codeMirror"
        v-model="updateTrackReg"
        :options="editorOptions"
      ></codemirror>
      <span slot="footer" class="dialog-footer">
        <el-button @click="CodedialogVisible2 = false">取 消</el-button>
        <el-button type="primary" @click="CodedialogVisible2 = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  GetAdvertise,
  AddAdvertise,
  DeleteAdvertise,
  UpdateAdvertise,
  RandomChannelCode,
} from "@/api/api";
import "codemirror/mode/javascript/javascript.js"; // 根据需要引入其他语言支持

export default {
  data() {
    return {
      data: {},
      tableData: [],
      addChannel: "",
      addTrackView: "",
      addTrackReg: "",
      addTrackFirstPay: "",
      updateChannel: "",
      updateTrackView: "",
      updateTrackReg: "",
      updateTrackFirstPay: "",
      updatePixelCode: "",
      randomString: "", // 初始化随机字符串为空
      dialogVisible: false,
      EditdialogVisible: false,
      CodedialogVisible: false,
      CodedialogVisible1: false,
      CodedialogVisible2: false,
      code: 'console.log("Hello, CodeMirror!");', // 初始代码内容
      editorOptions: {
        mode: "javascript", // 语言模式
        lineNumbers: true, // 显示行号
        theme: "monokai", // 主题，可以根据需要更换主题
      },
    };
  },
  methods: {
    openAddDialog() {
      this.dialogVisible = true;
      this.RandomCode();
      this.addChannel = this.randomString;
    },
    test() {
      this.$message.success(this.data);
    },
    OpenEditDialog(row) {
      this.data = row;
      this.updateChannel = this.data.channel;
      this.updatePixelCode = this.data.pixel_code;
      this.EditdialogVisible = true;
    },
    OpenCodeDialog() {
      this.code = "";
      this.$nextTick(() => {
        this.updateTrackView = this.data.html;
        this.$message.success(this.updateTrackView);
        this.CodedialogVisible = true;
      });
    },
    OpenCodeDialog1() {
      this.code = "";
      this.$nextTick(() => {
        this.updateTrackFirstPay = this.data.track_first_pay;
        this.$message.success(this.updateTrackFirstPay);
        this.CodedialogVisible1 = true;
      });
    },
    OpenCodeDialog2() {
      this.code = "";
      this.$nextTick(() => {
        this.updateTrackReg = this.data.track_reg;
        this.$message.success(this.updateTrackReg);
        this.CodedialogVisible2 = true;
      });
    },
    onCodeDialogOpen() {
      this.$nextTick(() => {
        if (this.$refs.codeMirror) {
          this.$refs.codeMirror.refresh();
        }
      });
    },
    GetAdvertises() {
      GetAdvertise().then((res) => {
        if (res.data.code === 200) {
          this.tableData = res.data.data;
        } else {
          this.$message({
            message: res.data.msg,
            type: "warning",
          });
        }
      });
    },
    AddAdvertises() {
      const token = localStorage.getItem("token"); // 取出 token
      AddAdvertise(
        token,
        this.addChannel,
        this.addTrackView,
        this.addTrackReg,
        this.addTrackFirstPay
      ).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("添加成功");
          this.GetAdvertises();
          this.dialogVisible = false;
        } else {
          this.$message({
            message: res.data.msg,
            type: "warning",
          });
        }
      });
    },
    DeleteAdvertises(id) {
      const token = localStorage.getItem("token"); // 取出 token
      DeleteAdvertise(token, id).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("删除成功");
          this.GetAdvertises();
        } else {
          this.$message({
            message: res.data.msg,
            type: "warning",
          });
        }
      });
    },
    UpdateAdvertises() {
      const token = localStorage.getItem("token"); // 取出 token
      UpdateAdvertise(
        token,
        this.updateTrackView,
        this.updateTrackReg,
        this.updateTrackFirstPay,
        this.updateChannel,
        this.updatePixelCode
      ).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("修改成功");
          this.GetAdvertises();
        } else {
          this.$message({
            message: res.data.msg,
            type: "warning",
          });
        }
      });
    },
    RandomCode() {
      RandomChannelCode().then((res) => {
        if (res.data.code === 200) {
          this.randomString = res.data.data.code;
          this.addChannel = this.randomString;
          this.updateChannel = this.randomString;
          console.log(this.randomString);
          console.log(this.addChannel);
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
  },
  created() {
    this.GetAdvertises();
  },
};
</script>

import axios from "axios";

const axiosInstance = axios.create({
  baseURL: "https://service.venice777.com",
});

// 登录接口
export const Login = (username, password) => {
  return axiosInstance.post("/api/admin/login", {
    username: username,
    password: password,
  });
};

// 注销登录
export const Logout = (token) => {
  return axiosInstance.post("/api/admin/logout", {
    token: token,
  });
};

// 修改密码
export const ChangePassword = (token, oldPassword, newPassword) => {
  return axiosInstance.post("/api/admin/changePwd", {
    token: token,
    oldPassword: oldPassword,
    newPassword: newPassword,
  });
};

// Dashboard接口
export const Dashboard = (token) => {
  return axiosInstance.post("/api/admin/dashboard", {
    token: token,
  });
};

// 获取充值列表
export const RechargeList = (token, page, list) => {
  return axiosInstance.post("/api/admin/rechargeList", {
    token: token,
    page: page,
    list: list,
  });
};

// 会员信息列表
export const GetMembersList = (token, page, list) => {
  return axiosInstance.post("/api/admin/membersList", {
    token: token,
    page: page,
    list: list,
  });
};

// 获取提现列表
export const GetWithdrawList = (token, page, list, status) => {
  return axiosInstance.post("/api/admin/withdrawList", {
    token: token,
    page: page,
    list: list,
    status: status,
  });
};

// 提现审核
export const WithdrawAudit = (token, chanid, orderNo, type, memo) => {
  return axiosInstance.post("/api/admin/withdrawReview", {
    token: token,
    chanid: chanid,
    orderNo: orderNo,
    type: type,
    memo: memo,
  });
};

// 获取提现通道
export const GetDrawChannelList = () => {
  return axiosInstance.get("/api/admin/drawChannelList");
};

// 获取会员信息
export const viewMember = (token, userId) => {
  return axiosInstance.post("/api/admin/viewMember", {
    token: token,
    userId: userId,
  });
};

// 编辑会员信息 会员信息部分使用字符串传给后端
export const editMember = (token, userId, json) => {
  return axiosInstance.post("/api/admin/editMember", {
    token: token,
    userId: userId,
    json: json,
  });
};

// 获取轮播图信息
export const GetBannerList = () => {
  return axiosInstance.post("/api/admin/bannerList", {});
};

// 删除轮播图信息
export const DeleteBanner = (token, id) => {
  return axiosInstance.post("/api/admin/deleteBanner", {
    token: token,
    bannerid: id,
  });
};

//添加轮播图信息
export const AddBanner = (token, bannerUrl, url, priority) => {
  return axiosInstance.post("/api/admin/addBanner", {
    token: token,
    banner_url: bannerUrl, // 确保参数名与服务器预期的一致
    url: url,
    priority: priority,
  });
};

// 修改轮播图信息
export const EditBanner = (token, id, url, bannerUrl, priority) => {
  return axiosInstance.post("/api/admin/editBanner", {
    token: token,
    bannerid: id,
    url: url,
    bannerUrl: bannerUrl,
    priority: priority,
  });
};

// 获取三方游戏列表
export const GetGameList = (page, list) => {
  return axiosInstance.post("/api/admin/thirdGamesList", {
    page: page,
    list: list,
  });
};

//获取三方游戏记录
export const GetGameHistory = (token, page, list) => {
  return axiosInstance.post("/api/admin/thirdGamesRecord", {
    token: token,
    page: page,
    list: list,
  });
};

// 获取订单信息(充值提现通用)
export const GetOrder = (token, orderNo, type) => {
  return axiosInstance.post("/api/admin/viewOrder", {
    token: token,
    orderNo: orderNo,
    type: type,
  });
};

// 删除订单信息(充值提现通用)
export const DeleteOrder = (token, orderNo) => {
  return axiosInstance.post("/api/admin/deleteOrder", {
    token: token,
    orderNo: orderNo,
  });
};

// 获取广告渠道列表
export const GetAdvertise = () => {
  return axiosInstance.post("/api/admin/advertisementChannelList");
};

// 添加广告渠道
export const AddAdvertise = (
  token,
  channel,
  trackView,
  trackReg,
  trackFirstPay
) => {
  return axiosInstance.post("/api/admin/addAdvertisementChannel", {
    token: token,
    channel: channel,
    trackView: trackView,
    trackReg: trackReg,
    trackFirstPay: trackFirstPay,
  });
};

// 删除广告渠道
export const DeleteAdvertise = (token, id) => {
  return axiosInstance.post("/api/admin/deleteAdvertisementChannel", {
    token: token,
    id: id,
  });
};

// 修改广告渠道
export const UpdateAdvertise = (
  token,
  channel,
  trackView,
  trackReg,
  trackFirstPay
) => {
  return axiosInstance.post("/api/admin/editAdvertisementChannel", {
    token: token,
    channel: channel,
    trackView: trackView,
    trackReg: trackReg,
    trackFirstPay: trackFirstPay,
  });
};

// 随机生成推广渠道代码
export const RandomChannelCode = () => {
  return axiosInstance.post("/api/admin/randomChannelCode");
};

// 获取全局配置菜单
export const GetGlobalConfig = () => {
  return axiosInstance.post("/api/admin/globalConfigList");
};

// 新增全局配置
export const AddGlobalConfig = (token, name, title, value) => {
  return axiosInstance.post("/api/admin/addGlobalConfig", {
    token: token,
    name: name,
    title: title,
    value: value,
  });
};

// 删除全局配置
export const DeleteGlobalConfig = (token, id) => {
  return axiosInstance.post("/api/admin/deleteGlobalConfig", {
    token: token,
    id: id,
  });
};

// 编辑全局配置
export const UpdateGlobalConfig = (token, id, name, title, value) => {
  return axiosInstance.post("/api/admin/editGlobalConfig", {
    token: token,
    id: id,
    name: name,
    title: title,
    value: value,
  });
};

// 获取活动列表
export const GetActivity = () => {
  return axiosInstance.post("/api/admin/activityList");
};

// 编辑活动列表
export const UpdateActivity = (
  token,
  coverUrl,
  countUrl,
  eventAddress,
  bannerId,
  bannerUrl,
  bannerTitle,
  priority,
  memo
) => {
  return axiosInstance.post("/api/admin/editActivity", {
    token: token,
    coverUrl: coverUrl,
    countUrl: countUrl,
    eventAddress: eventAddress,
    bannerId: bannerId,
    bannerUrl: bannerUrl,
    bannerTitle: bannerTitle,
    priority: priority,
    memo: memo,
  });
};

// 加载广告渠道记录(打点记录)
export const GetAdvertisementRecord = (page, list) => {
  return axiosInstance.post("/api/admin/advertisementRecord", {
    page: page,
    list: list,
  });
};

// 获取客服分类列表
export const GetServeCategoryList = () => {
  return axiosInstance.post("/api/admin/serviceCategoryList");
};

// 添加客服分类
export const AddServeCategory = (token, name) => {
  return axiosInstance.post("/api/admin/addServiceCategory", {
    token: token,
    name: name,
  });
};

// 删除客服分类
export const DeleteServeCategory = (token, id) => {
  return axiosInstance.post("/api/admin/deleteServiceCategory", {
    token: token,
    id: id,
  });
};

// 编辑客服分类
export const UpdateServeCategory = (token, id, name) => {
  return axiosInstance.post("/api/admin/editServiceCategory", {
    token: token,
    id: id,
    name: name,
  });
};

// 查看客服成员列表
export const GetServeMemberList = () => {
  return axiosInstance.post("/api/admin/serviceMemberList");
};

// 添加客服成员
export const AddServeMember = (token, categoryId, username, url) => {
  return axiosInstance.post("/api/admin/addServiceMember", {
    token: token,
    categoryId: categoryId,
    username: username,
    url: url,
  });
};

// 删除客服成员
export const DeleteServeMember = (token, id) => {
  return axiosInstance.post("/api/admin/deleteServiceMember", {
    token: token,
    id: id,
  });
};

// 编辑客服成员
export const UpdateServeMember = (token, categoryId, username, url) => {
  return axiosInstance.post("/api/admin/editServiceMember", {
    token: token,
    categoryId: categoryId,
    username: username,
    url: url,
  });
};

// 获取视频列表
export const GetVideoList = (token, page, list) => {
  return axiosInstance.post("/api/admin/moviesList", {
    token: token,
    page: page,
    list: list,
  });
};

// 获取站点公告列表
export const GetNoticeList = () => {
  return axiosInstance.post("/api/admin/noticeList", {});
};

// 添加站点公告
export const AddNotice = (token, title, siteMessage) => {
  return axiosInstance.post("/api/admin/addNotice", {
    token: token,
    title: title,
    siteMessage: siteMessage,
  });
};

// 删除站点公告
export const DeleteNotice = (token, id) => {
  return axiosInstance.post("/api/admin/deleteNotice", {
    token: token,
    id: id,
  });
};

// 编辑站点公告
export const EditNotice = (token, id, title, siteMessage) => {
  return axiosInstance.post("/api/admin/editNotice", {
    token: token,
    id: id,
    title: title,
    siteMessage: siteMessage,
  });
};

// 获取签到规则列表
export const GetSignInList = () => {
  return axiosInstance.post("/api/admin/signInMoneyList");
};

// 编辑签到规则
export const EditSignIn = (token, id, money) => {
  return axiosInstance.post("/api/admin/editSignInMoney", {
    token: token,
    id: id,
    money: money,
  });
};

// 获取玩家签到记录
export const GetSignInRecord = (token, page, list) => {
  return axiosInstance.post("/api/admin/signInRecord", {
    token: token,
    page: page,
    list: list,
  });
};

// 获取充值通道列表
export const GetPayChannelList = (token) => {
  return axiosInstance.post("/api/admin/payChannelList", {
    token: token,
  });
};

// 获取充值大分类列表
export const GetPayTypeList = (token) => {
  return axiosInstance.post("/api/admin/payTypeList", {
    token: token,
  });
};

<template>
  <div>
    <div>
      <el-button
        type="primary"
        style="margin-left: 10px"
        @click="dialogVisible = true"
        >添加全局配置</el-button
      >
    </div>
    <el-table :data="tableData" border stripe style="width: 100%">
      <el-table-column type="selection"> </el-table-column>
      <el-table-column prop="id" label="id"> </el-table-column>
      <el-table-column prop="name" label="配置名称"> </el-table-column>
      <el-table-column prop="title" label="配置标题"> </el-table-column>
      <el-table-column prop="value" label="配置值"> </el-table-column>
      <el-table-column prop="操作">
        <template slot-scope="scope">
          <el-button type="primary" @click="OpenEditDialog(scope.row)"
            >编辑</el-button
          >
          <el-popconfirm
            title="数据一但删除不可恢复！您确定要删除吗"
            @confirm="DeleteGlobalConfigs(scope.row.id)"
          >
            <el-button slot="reference" type="danger" style="margin-left: 10px"
              >删除</el-button
            >
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <!-- 添加dialog -->
    <el-dialog title="新增全局配置" :visible.sync="dialogVisible" width="30%">
      <el-form>
        <el-form-item label="配置名称"
          ><el-input v-model="addname"></el-input
        ></el-form-item>
        <el-form-item label="配置标题"
          ><el-input v-model="addtitle"></el-input
        ></el-form-item>
        <el-form-item label="配置值"
          ><el-input v-model="addvalue"></el-input
        ></el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="AddGlobalConfigs()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 编辑dialog -->
    <el-dialog
      title="新增全局配置"
      :visible.sync="EditDialogVisible"
      width="30%"
    >
      <el-form>
        <el-form-item label="配置ID"
          ><el-input v-model="editid" :value="editid"></el-input
        ></el-form-item>
        <el-form-item label="配置名称"
          ><el-input v-model="editname" :value="editname"></el-input
        ></el-form-item>
        <el-form-item label="配置标题"
          ><el-input v-model="edittitle" :value="edittitle"></el-input
        ></el-form-item>
        <el-form-item label="配置值"
          ><el-input v-model="editvalue" :value="editvalue"></el-input
        ></el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="EditDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="UpdateGlobalConfigs()"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  AddGlobalConfig,
  GetGlobalConfig,
  DeleteGlobalConfig,
  UpdateGlobalConfig,
} from "@/api/api";

export default {
  data() {
    return {
      tableData: [],
      dialogVisible: false,
      EditDialogVisible: false,
      addname: "",
      addtitle: "",
      addvalue: "",
      editid: "",
      editname: "",
      edittitle: "",
      editvalue: "",
    };
  },
  methods: {
    OpenEditDialog(row) {
      this.row = row;
      this.editid = row.id;
      this.editname = row.name;
      this.edittitle = row.title;
      this.editvalue = row.value;
      this.EditDialogVisible = true;
    },
    GetGlobalConfigs() {
      GetGlobalConfig().then((res) => {
        if (res.data.code === 200) {
          this.tableData = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    AddGlobalConfigs() {
      const token = localStorage.getItem("token"); // 取出 token
      AddGlobalConfig(token, this.addname, this.addtitle, this.addvalue).then(
        (res) => {
          if (res.data.code === 200) {
            this.$message.success("添加成功");
            this.GetGlobalConfigs();
            this.dialogVisible = false;
          } else {
            this.$message.error(res.data.msg);
          }
        }
      );
    },
    DeleteGlobalConfigs(id) {
      const token = localStorage.getItem("token"); // 取出 token
      DeleteGlobalConfig(token, id).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("删除成功");
          this.GetGlobalConfigs();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    UpdateGlobalConfigs() {
      const token = localStorage.getItem("token"); // 取出 token
      UpdateGlobalConfig(
        token,
        this.editid,
        this.editname,
        this.edittitle,
        this.editvalue
      ).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("修改成功");
          this.GetGlobalConfigs();
          this.EditDialogVisible = false;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
  },
  created() {
    this.GetGlobalConfigs();
  },
};
</script>

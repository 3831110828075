<template>
  <div>
    <div style="margin-left: 10px; display: flex">
      <el-button type="success" @click="addDialogVisible = true"
        >添加</el-button
      >
      <div>
        <el-input
          placeholder="公司名称"
          style="width: 250px; margin-left: 10px"
        ></el-input>
        <el-input
          placeholder="渠道包名"
          style="width: 250px; margin-left: 10px"
        ></el-input>
      </div>
      <el-date-picker
        v-model="startDate"
        align="right"
        type="date"
        placeholder="选择起始日期"
        value-format="yyyy-MM-dd"
        :picker-options="pickerOptions"
        style="margin-left: 10px"
      >
      </el-date-picker>
      <el-date-picker
        v-model="endDate"
        align="right"
        type="date"
        placeholder="选择结束日期"
        value-format="yyyy-MM-dd"
        :picker-options="pickerOptions"
        style="margin-left: 10px"
      >
      </el-date-picker>
      <el-button type="primary" style="margin-left: 10px">搜索</el-button>
    </div>
    <el-table
      :data="displayedData"
      border
      stripe
      style="width: 100%; margin-top: 10px"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column prop="id" label="id"> </el-table-column>
      <el-table-column prop="packgeName" label="渠道包名"> </el-table-column>
      <el-table-column prop="username" label="用户名"> </el-table-column>
      <el-table-column prop="password" label="密码（加密后）">
      </el-table-column>
      <el-table-column prop="createTime" label="创建时间"> </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="primary" @click="openEditDialog(scope.row)"
            >编辑</el-button
          >
          <el-button type="danger">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页器 -->
    <el-pagination
      background
      layout="prev, pager, next"
      :total="allData.length"
      :current-page.sync="currentPage"
      :page-size.sync="pageSize"
      @current-change="updateData"
      style="position: fixed; bottom: 0; right: 0"
    >
    </el-pagination>
    <!-- 添加联运公司员工弹窗 -->
    <el-dialog
      title="添加联运公司"
      :visible.sync="addDialogVisible"
      width="30%"
    >
      <el-form>
        <el-form-item label="渠道包名">
          <el-input></el-input>
        </el-form-item>
        <el-form-item label="用户名">
          <el-input></el-input>
        </el-form-item>
        <el-form-item label="密码">
          <el-input></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addDialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 编辑联运公司员工弹窗 -->
    <el-dialog
      title="编辑联运公司"
      :visible.sync="editDialogVisible"
      width="30%"
    >
      <el-form>
        <el-form-item label="渠道包名">
          <el-input :placeholder="ShowPackgeName"></el-input>
        </el-form-item>
        <el-form-item label="用户名">
          <el-input :placeholder="ShowUserName"></el-input>
        </el-form-item>
        <el-form-item label="密码">
          <el-input :placeholder="ShowPassWord"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editDialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      addDialogVisible: false,
      editDialogVisible: false,
      startDate: "",
      endDate: "",
      currentPage: 1,
      pageSize: 8,
      displayedData: [],
      allData: [],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      tableData: [
        {
          id: 1,
          packageName: "com.test.PackageName",
          username: "test",
          password: "123456",
          createTime: "2022-01-01",
        },
        {
          id: 2,
          packageName: "com.test.PackageName2",
          username: "test2",
          password: "123456",
          createTime: "2022-01-02",
        },
        {
          id: 3,
          packageName: "com.test.PackageName3",
          username: "test3",
          password: "123456",
          createTime: "2022-01-03",
        },
        {
          id: 4,
          packageName: "com.test.PackageName4",
          username: "test4",
          password: "123456",
          createTime: "2022-01-04",
        },
        {
          id: 5,
          packageName: "com.test.PackageName5",
          username: "test5",
          password: "123456",
          createTime: "2022-01-05",
        },
        {
          id: 6,
          packageName: "com.test.PackageName6",
          username: "test6",
          password: "123456",
          createTime: "2022-01-06",
        },
      ],
    };
  },
  created() {
    this.allData = this.tableData;
    this.updateData();
  },
  methods: {
    openEditDialog(row) {
      this.editDialogVisible = true;
      this.ShowPackgeName = row.packageName;
      this.ShowUserName = row.username;
      this.ShowPassWord = row.password;
    },
    updateData() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = this.currentPage * this.pageSize;
      this.displayedData = this.allData.slice(start, end);
    },
  },
};
</script>

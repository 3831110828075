<template>
  <div>
    <div>
      <el-button
        type="success"
        style="margin-left: 10px; margin-bottom: 10px"
        @click="dialogVisible = true"
        >添加站点公告</el-button
      >
    </div>
    <!-- 数据展示表格 -->
    <el-table
      :data="tableData"
      v-loading="loading"
      border
      stripe
      style="width: 100%"
    >
      <el-table-column type="selection"> </el-table-column>
      <el-table-column prop="id" label="id"> </el-table-column>
      <el-table-column prop="title" label="站点公告标题"> </el-table-column>
      <el-table-column prop="site_message" label="站点公告内容">
      </el-table-column>
      <el-table-column prop="addtime" label="添加时间">
        <template slot-scope="scope">
          {{ formatDate(scope.row.addtime) }}
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="primary" @click="OpenEditDialog(scope.row)"
            >编辑</el-button
          >
          <el-popconfirm
            title="数据删除后不可恢复！请谨慎操作！确定删除吗"
            @confirm="DeleteNotices(scope.row.id)"
          >
            <el-button type="danger" slot="reference" style="margin-left: 10px"
              >删除</el-button
            >
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <!-- 添加数据dialog -->
    <el-dialog title="添加站点公告" :visible.sync="dialogVisible" width="30%">
      <el-form>
        <el-form-item label="站点公告标题">
          <el-input v-model="addTitle"></el-input>
        </el-form-item>
        <el-form-item label="站点公告标题">
          <el-input
            v-model="addSite_message"
            type="textarea"
            :rows="3"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="AddNotices()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 编辑数据dialog -->
    <el-dialog
      title="编辑站点公告"
      :visible.sync="EditDialogVisible"
      width="30%"
    >
      <el-form>
        <el-form-item label="站点公告标题">
          <el-input v-model="editTitle"></el-input>
        </el-form-item>
        <el-form-item label="站点公告标题">
          <el-input
            v-model="editSite_message"
            type="textarea"
            :rows="3"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="EditDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="EditNotices()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { GetNoticeList, AddNotice, DeleteNotice, EditNotice } from "@/api/api";
import moment from "moment";

export default {
  data() {
    return {
      tableData: [],
      dialogVisible: false,
      EditDialogVisible: false,
      addTitle: "",
      addSite_message: "",
      editId: "",
      editTitle: "",
      editSite_message: "",
      loading: false,
    };
  },
  methods: {
    //打开编辑弹窗并赋值
    OpenEditDialog(row) {
      this.EditDialogVisible = true;
      this.editId = row.id;
      this.editTitle = row.title;
      this.editSite_message = row.site_message;
    },
    //时间格式化
    formatDate(date) {
      return moment.unix(date).format("YYYY-MM-DD HH:mm:ss");
    },
    GetNoticeLists() {
      this.loading = true;
      GetNoticeList().then((res) => {
        if (res.data.code === 200) {
          this.tableData = res.data.data;
          this.loading = false;
        } else {
          this.loading = false;
          this.$message({
            message: res.data.msg,
            type: "warning",
          });
        }
      });
    },
    AddNotices() {
      const token = localStorage.getItem("token");
      AddNotice(token, this.addTitle, this.addSite_message).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("添加成功");
          this.GetNoticeLists();
          this.dialogVisible = false;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    DeleteNotices(id) {
      const token = localStorage.getItem("token");
      DeleteNotice(token, id).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("删除成功");
          this.GetNoticeLists();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    EditNotices() {
      const token = localStorage.getItem("token");
      EditNotice(
        token,
        this.editId,
        this.editTitle,
        this.editSite_message
      ).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("编辑成功");
          this.EditDialogVisible = false;
          this.GetNoticeLists();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
  },
  created() {
    this.GetNoticeLists();
  },
};
</script>

<template>
  <div>
    <div>
      <el-button
        type="success"
        style="margin-left: 10px"
        @click="addDialogVisible = true"
        >添加轮播图</el-button
      >
    </div>
    <el-table :data="tableData" stripe style="width: 100%">
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column prop="id" label="ID" width="80"> </el-table-column>
      <el-table-column prop="url" label="转跳到URL"> </el-table-column>
      <el-table-column prop="priority" label="优先级"> </el-table-column>
      <el-table-column prop="create_time" label="创建时间"> </el-table-column>
      <el-table-column prop="update_time" label="更新时间"> </el-table-column>
      <el-table-column prop="轮播图" label="swipe" width="300">
        <template slot-scope="scope">
          <el-image :src="scope.row.banner_url"></el-image>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="200">
        <template slot-scope="scope">
          <el-button @click="openEditDialog(scope.row)" type="primary"
            >修改</el-button
          >
          <el-popconfirm
            title="请注意一旦删除后不可恢复，请谨慎操作。是否真的删除该轮播图？"
            @confirm="deleteBanner(scope.row.id)"
          >
            <el-button slot="reference" type="danger" style="margin-left: 10px"
              >删除</el-button
            >
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <!-- 添加轮播图部分 -->
    <el-dialog title="添加轮播图" :visible.sync="addDialogVisible" width="30%">
      <el-form>
        <el-form-item label="轮播图源URL" style="display: flex">
          <el-input v-model="add.banner_url" style="width: 240px"></el-input>
          <el-button
            type="primary"
            style="margin-left: 10px"
            @click="uploadImage()"
            >上传</el-button
          >
        </el-form-item>
        <el-form-item label="轮播图预览">
          <el-image
            :src="add.banner_url"
            alt="swipe"
            style="width: 100%; height: 300px"
          ></el-image>
        </el-form-item>
        <el-form-item label="转跳URL">
          <el-input v-model="add.url" style="width: 350px"></el-input>
        </el-form-item>
        <el-form-item label="优先级">
          <el-input v-model="add.priority" style="width: 360px"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addDialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 编辑轮播图部分 -->
    <el-dialog title="编辑轮播图" :visible.sync="editDialogVisible" width="60%">
      <!-- <el-form-item label="轮播图预览">
        <img :src="imageUrl" alt="" style="width: 100%; height: 300px" />
      </el-form-item> -->

      <el-row :gutter="20">
        <el-col :span="12" :offset="0">
          <el-form>
            <el-form-item label="轮播图源URL" style="display: flex">
              <el-input
                v-model="updateImg.banner_url"
                style="width: 240px"
              ></el-input>
              <el-button
                type="primary"
                style="margin-left: 10px"
                @click="uploadImage()"
                >上传</el-button
              >
            </el-form-item>
            <el-form-item label="转跳URL">
              <el-input v-model="updateImg.url" style="width: 350px"></el-input>
            </el-form-item>
            <el-form-item label="优先级">
              <el-input
                v-model="updateImg.priority"
                style="width: 360px"
              ></el-input>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="12" :offset="0">
          <span>轮播图预览</span>
          <el-image
            :src="ShowimageUrl"
            alt="swipe"
            style="width: 100%; height: 300px"
          ></el-image>
        </el-col>
      </el-row>

      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editDialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { AddBanner, DeleteBanner, EditBanner, GetBannerList } from "@/api/api";

export default {
  data() {
    return {
      addDialogVisible: false,
      editDialogVisible: false,
      imageUrl: "",
      imgUrl: "",
      jumpUrl: "",
      first: "",
      tableData: [],
      add: [],
      updateImg: [],
    };
  },
  methods: {
    test() {
      this.$message.success("yes");
    },
    deleteBanner(bannerId) {
      const token = localStorage.getItem("token"); // 取出 token
      DeleteBanner(token, bannerId).then((res) => {
        if (res.data.code === 200) {
          this.$message.success(res.data.msg);
          this.editDialogVisible = false;
        } else {
          this.$message.error(res.data.msg);
        }
        this.editDialogVisible = false;
      });
    },
    uploadImage() {
      this.imageUrl = this.imgUrl;
      console.log(this.imageUrl); // 现在应该能看到输入的图片链接
    },
    openEditDialog(row) {
      this.editDialogVisible = true;
      this.ShowimgUrl = row.banner_url;
      this.ShowimageUrl = row.banner_url;
      this.Showurl = row.url;
      this.Showpriority = row.priority;
    },
    addBanner() {
      const token = localStorage.getItem("token"); // 取出 token
      AddBanner(
        token,
        this.add.url,
        this.add.banner_url,
        this.add.priority
      ).then((res) => {
        if (res.data.code === 200) {
          this.$message.success(res.data.msg);
          this.addDialogVisible = false;
        } else {
          this.$message.error(res.data.msg);
        }
        this.addDialogVisible = false;
      });
    },
    updateBanner() {
      const token = localStorage.getItem("token"); // 取出 token
      EditBanner(
        token,
        this.updateImg.banner_url,
        this.updateImg.url,
        this.updateImg.priority
      ).then((res) => {
        if (res.data.code === 200) {
          this.$message.success(res.data.msg);
          this.editDialogVisible = false;
        } else {
          this.$message.error(res.data.msg);
        }
        this.editDialogVisible = false;
      });
    },
  },
  created() {
    GetBannerList().then((res) => {
      if (res.data.code === 200) {
        this.tableData = res.data.data;
      } else {
        this.$message.error(res.data.msg);
      }
    });
  },
};
</script>

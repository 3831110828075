<template>
  <div>
    <!-- 数据展示表格 -->
    <div>
      <el-button
        type="success"
        style="margin-left: 10px"
        @click="dialogVisible = true"
        >添加客服分类</el-button
      >
    </div>
    <el-table :data="tableData" stripe style="width: 100%">
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column prop="id" label="id"> </el-table-column>
      <el-table-column prop="name" label="类型名称"> </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="primary" @click="OpenEditDialog(scope.row)"
            >编辑</el-button
          >
          <el-popconfirm
            title="删除后客服数据无法恢复！确认删除吗"
            @confirm="DeleteServeCategorys(scope.row.id)"
          >
            <el-button slot="reference" type="danger" style="margin-left: 10px"
              >删除</el-button
            >
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <!-- 添加分类弹窗 -->
    <el-dialog title="添加客服分类" :visible.sync="dialogVisible" width="30%">
      <el-input v-model="name" placeholder="请输入客服分类"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="AddServeCategorys()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 编辑弹窗 -->
    <el-dialog
      title="编辑客服分类"
      :visible.sync="EditDialogVisible"
      width="30%"
    >
      <el-form>
        <el-form-item label="编辑客服分类ID">
          <el-input v-model="editId" placeholder="请输入客服ID"></el-input>
        </el-form-item>
        <el-form-item label="编辑客服分类名称">
          <el-input v-model="editName" placeholder="请输入客服分类"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="EditDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="UpdateServeCategorys()"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  AddServeCategory,
  DeleteServeCategory,
  GetServeCategoryList,
  UpdateServeCategory,
} from "@/api/api";
export default {
  data() {
    return {
      tableData: [],
      name: "",
      editId: "",
      editName: "",
      dialogVisible: false,
      EditDialogVisible: false,
    };
  },
  methods: {
    GetServeCategoryLists() {
      GetServeCategoryList().then((res) => {
        if (res.data.code == 200) {
          this.tableData = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    AddServeCategorys() {
      const token = localStorage.getItem("token");
      AddServeCategory(token, this.name).then((res) => {
        if (res.data.code == 200) {
          this.$message.success("添加成功");
          this.GetServeCategoryLists();
          this.dialogVisible = false;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    DeleteServeCategorys(id) {
      const token = localStorage.getItem("token");
      DeleteServeCategory(token, id).then((res) => {
        if (res.data.code == 200) {
          this.$message.success("删除成功");
          this.GetServeCategoryLists();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    UpdateServeCategorys() {
      const token = localStorage.getItem("token");
      UpdateServeCategory(token, this.editId, this.editName).then((res) => {
        if (res.data.code == 200) {
          this.$message.success("修改成功");
          this.GetServeCategoryLists();
          this.EditDialogVisible = false;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    OpenEditDialog(row) {
      this.row = row;
      this.editId = row.id;
      this.editName = row.name;
      this.EditDialogVisible = true;
    },
  },
  created() {
    this.GetServeCategoryLists();
  },
};
</script>

<template>
  <div>
    <div style="margin: 0 10px 0 10px">
      <el-row :gutter="20">
        <el-col span="6">
          <t-card
            title="今日充值"
            hover-shadow
            style="text-align: center; color: #33a361"
          >
            <h1>{{ dataShow.todayRecharge }}</h1>
          </t-card>
        </el-col>
        <el-col span="6">
          <t-card
            title="今日提现"
            hover-shadow
            style="text-align: center; color: #33a361"
          >
            <h1>{{ dataShow.todayWithdraw }}</h1>
          </t-card>
        </el-col>
        <el-col span="6">
          <t-card
            title="总充值"
            hover-shadow
            style="text-align: center; color: #33a361"
          >
            <h1>{{ dataShow.totalRecharge }}</h1>
          </t-card>
        </el-col>
        <el-col span="6">
          <t-card
            title="总提现"
            hover-shadow
            style="text-align: center; color: #33a361"
          >
            <h1>{{ dataShow.totalWithdraw }}</h1>
          </t-card>
        </el-col>
      </el-row>
      <el-row :gutter="20" style="margin-top: 10px">
        <el-col span="6">
          <t-card
            title="今日新增会员数量"
            hover-shadow
            style="text-align: center; color: #33a361"
          >
            <h1>{{ dataShow.todayIncrement }}</h1>
          </t-card>
        </el-col>
        <el-col span="6">
          <t-card
            title="总会员数量"
            hover-shadow
            style="text-align: center; color: #33a361"
          >
            <h1>{{ dataShow.totalMembers }}</h1>
          </t-card>
        </el-col>
        <el-col span="6">
          <t-card
            title="今日充值会员数量"
            hover-shadow
            style="text-align: center; color: #33a361"
          >
            <h1>{{ dataShow.todayRechargeMembers }}</h1>
          </t-card>
        </el-col>
        <el-col span="6">
          <t-card
            title="今日提现会员数量"
            hover-shadow
            style="text-align: center; color: #33a361"
          >
            <h1>{{ dataShow.todayWithdrawMembers }}</h1>
          </t-card>
        </el-col>
      </el-row>
      <el-row style="margin-top: 10px">
        <el-col :span="12">
          <div ref="chart" style="width: 100%; height: 400px"></div>
        </el-col>
        <el-col :span="12">
          <div ref="chart1" style="width: 100%; height: 400px"></div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { Dashboard } from "../api/api.js";
import * as echarts from "echarts";

export default {
  data() {
    return {
      data: {
        username: "",
        avatar: "",
        oldPassword: "",
        newPassword: "",
      },
      dataShow: {
        todayRecharge: 0,
        todayWithdraw: 0,
        totalRecharge: 0,
        totalWithdraw: 0,
        todayIncrement: 0,
        totalMembers: 0,
        todayRechargeMembers: 0,
        todayWithdrawMembers: 0,
      },
    };
  },
  created() {
    const token = localStorage.getItem("token"); // 取出 token
    // 取出账户信息
    this.username = localStorage.getItem("username");
    this.avatar = localStorage.getItem("avatar");
    Dashboard(token).then((res) => {
      if (res.data.code == 200) {
        this.data = res.data.data;
        this.dataShow = res.data.data;
        console.log(this.dataShow);
        this.updateCharts(); // 数据加载完成后更新图表
      } else {
        this.$message.error(res.data.msg + `，即将清除账号信息，返回登录页面`);
        setTimeout(() => {
          localStorage.removeItem("isLoggedIn");
          localStorage.removeItem("token");
          localStorage.removeItem("username");
          localStorage.removeItem("avatar");
          window.location.reload();
        }, 3000);
      }
    });
  },
  mounted() {
    this.initCharts(); // 初始化图表
  },
  methods: {
    initCharts() {
      if (!this.chart) {
        this.chart = echarts.init(this.$refs.chart);
      }
      if (!this.chart1) {
        this.chart1 = echarts.init(this.$refs.chart1);
      }
    },
    updateCharts() {
      const option1 = {
        title: {
          text: "充值提现数据可视化",
          left: "center",
        },
        tooltip: {
          trigger: "item",
        },
        legend: {
          orient: "vertical",
          left: "left",
        },
        series: [
          {
            name: "充值数据",
            type: "pie",
            radius: "50%",
            data: [
              { value: this.data.todayRecharge, name: "今日充值" },
              { value: this.data.todayWithdraw, name: "今日提现" },
              { value: this.data.totalRecharge, name: "总充值" },
              { value: this.data.totalWithdraw, name: "总提现" },
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };

      const option2 = {
        title: {
          text: "会员数据可视化",
          left: "center",
        },
        tooltip: {
          trigger: "item",
        },
        legend: {
          orient: "vertical",
          left: "left",
        },
        series: [
          {
            name: "提现数据",
            type: "pie",
            radius: "50%",
            data: [
              { value: this.data.todayIncrement, name: "今日新增会员数量" },
              { value: this.data.totalMembers, name: "总会员数量" },
              {
                value: this.data.todayRechargeMembers,
                name: "今日充值会员数量",
              },
              {
                value: this.data.todayWithdrawMembers,
                name: "今日提现会员数量",
              },
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };

      if (this.chart) {
        this.chart.setOption(option1);
      }
      if (this.chart1) {
        this.chart1.setOption(option2);
      }
    },
  },
  watch: {
    dataShow: {
      deep: true,
      handler() {
        this.updateCharts(); // 数据变化时更新图表
      },
    },
  },
};
</script>

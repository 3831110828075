<template>
  <div>
    <!-- 数据展示表格 -->
    <el-table :data="tableData" stripe style="width: 100%">
      <el-table-column type="selection"> </el-table-column>
      <el-table-column prop="id" label="id" width="55"> </el-table-column>
      <el-table-column prop="count_url" label="计数URL"> </el-table-column>
      <el-table-column prop="cover_url" label="封面URL"> </el-table-column>
      <el-table-column prop="event_address" label="事件地址"> </el-table-column>
      <el-table-column prop="banner_id" label="轮播图id"> </el-table-column>
      <el-table-column prop="banner_url" label="轮播图地址" width="250">
        <template slot-scope="scope">
          <el-image
            style="width: 250px; height: 100px"
            :src="scope.row.banner_url"
          ></el-image>
        </template>
      </el-table-column>
      <el-table-column prop="banner_title" label="轮播图标题">
      </el-table-column>
      <el-table-column prop="priority" label="优先级"> </el-table-column>
      <el-table-column prop="createtime" label="创建时间">
        <template slot-scope="scope">
          <span v-if="scope.row.createtime === null">&nbsp;</span>
          <span v-else-if="scope.row.createtime">{{
            formatDate(scope.row.createtime)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="memo" label="备注"> </el-table-column>
      <el-table-column prop="img" label="img">
        <template slot-scope="scope">
          <span
            style="
              width: 10px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            "
            >{{ scope.row.img }}</span
          >
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="primary" @click="OpenEditDialog(scope.row)"
            >编辑</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 编辑dialog -->
    <el-dialog title="编辑" :visible.sync="dialogVisible" width="50%">
      <el-row :gutter="20">
        <el-col :span="12" :offset="0">
          <el-form>
            <el-form-item label="计数URL">
              <el-input v-model="editcount_url"></el-input>
            </el-form-item>
            <el-form-item label="封面URL">
              <el-input v-model="editcover_url"></el-input>
            </el-form-item>
            <el-form-item label="事件地址">
              <el-input v-model="editevent_address"></el-input>
            </el-form-item>
            <el-form-item label="轮播图id">
              <el-input v-model="editbanner_id"></el-input>
            </el-form-item>
            <el-form-item label="轮播图地址">
              <el-input v-model="editbanner_url"></el-input>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="12" :offset="0">
          <el-form>
            <el-form-item label="轮播图标题">
              <el-input v-model="editbanner_title"></el-input>
            </el-form-item>
            <el-form-item label="优先级">
              <el-input v-model="editpriority"></el-input>
            </el-form-item>
            <el-form-item label="创建时间">
              <el-input v-model="editcreatetime"></el-input>
            </el-form-item>
            <el-form-item label="备注">
              <el-input v-model="editmemo"></el-input>
            </el-form-item>
            <el-button
              style="width: 100%"
              type="primary"
              @click="CodedialogVisible = true"
              >编辑img代码</el-button
            >
          </el-form>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog title="代码编辑" :visible.sync="CodedialogVisible" width="30%">
      <codemirror
        ref="codeMirror"
        v-model="editimg"
        :options="editorOptions"
      ></codemirror>
      <span slot="footer" class="dialog-footer">
        <el-button @click="CodedialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="CodedialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { GetActivity, UpdateActivity } from "@/api/api";
import "codemirror/mode/javascript/javascript.js";
import moment from "moment";

export default {
  data() {
    return {
      tableData: [],
      editcount_url: "",
      editcover_url: "",
      editevent_address: "",
      editbanner_id: "",
      editbanner_url: "",
      editbanner_title: "",
      editpriority: "",
      editcreatetime: "",
      editmemo: "",
      editimg: "",
      dialogVisible: false,
      CodedialogVisible: false,
      editorOptions: {
        mode: "javascript", // 语言模式
        lineNumbers: true, // 显示行号
        theme: "monokai", // 主题，可以根据需要更换主题
      },
    };
  },
  methods: {
    OpenEditDialog(row) {
      this.row = row;
      this.editcount_url = row.count_url;
      this.editcover_url = row.cover_url;
      this.editevent_address = row.event_address;
      this.editbanner_id = row.banner_id;
      this.editbanner_url = row.banner_url;
      this.editbanner_title = row.banner_title;
      this.editpriority = row.priority;
      this.editmemo = row.memo;
      this.editimg = row.img;
      this.dialogVisible = true;
    },
    GetActivityList() {
      GetActivity().then((res) => {
        if (res.data.code === 200) {
          this.tableData = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    UpdateActivitys() {
      const token = localStorage.getItem("token");
      UpdateActivity(
        token,
        this.editcount_url,
        this.editcover_url,
        this.editevent_address,
        this.editbanner_id,
        this.editbanner_url,
        this.editbanner_title,
        this.editpriority,
        this.editcreatetime,
        this.editmemo,
        this.editimg
      ).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("修改成功");
          this.GetActivityList();
          this.dialogVisible = false;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    formatDate(date) {
      return moment.unix(date).format("YYYY-MM-DD HH:mm:ss");
    },
  },
  created() {
    this.GetActivityList();
  },
};
</script>

<template>
  <div>
    <el-table :data="tableData" border stripe style="width: 100%">
      <el-table-column type="selection"> </el-table-column>
      <el-table-column prop="id" label="id" width="55"> </el-table-column>
      <el-table-column prop="platType" label="平台类型"> </el-table-column>
      <el-table-column prop="gameType" label="游戏类型"> </el-table-column>
      <el-table-column prop="gameCode" label="游戏代码"> </el-table-column>
      <el-table-column prop="gameName" label="游戏名称"> </el-table-column>
      <el-table-column label="图片">
        <template slot-scope="scope">
          <img :src="scope.row.imageUrl" alt="" style="width: 100px" />
        </template>
      </el-table-column>
      <el-table-column prop="is_hot" label="热门游戏"> </el-table-column>
      <el-table-column prop="des" label="权重"> </el-table-column>
      <el-table-column prop="is_show" label="是否显示"> </el-table-column>
      <el-table-column prop="category" label="游戏分类"> </el-table-column>
      <el-table-column label="操作" width="200">
        <el-button type="primary">编辑</el-button>
        <el-button type="danger">删除</el-button>
      </el-table-column>
    </el-table>
    <!-- 分页器 -->
    <el-pagination
      background
      @size-change="handleSizeChange"
      @current-change="handlePageChange"
      :current-page.sync="page"
      :page-sizes="[3, 5, 10, 20]"
      :page-size="size"
      layout="sizes, prev, pager, next"
      :total="total"
      style="position: fixed; right: 0"
    >
    </el-pagination>
  </div>
</template>

<script>
import { GetGameList } from "@/api/api";

export default {
  data() {
    return {
      tableData: [],
      page: 1,
      size: 3,
      total: 0,
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      GetGameList(this.page, this.size).then((res) => {
        if (res.data.code === 200) {
          this.tableData = res.data.data.data; // 注意这里的路径
          this.total = res.data.data.total; // 设置总页数
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    handlePageChange(newPage) {
      this.page = newPage;
      this.fetchData(); // 重新获取数据
    },
    handleSizeChange(newSize) {
      this.size = newSize;
      this.page = 1; // 重置到第一页
      this.fetchData(); // 重新获取数据
    },
  },
};
</script>

<template>
  <div>
    <!-- 数据展示表格 -->
    <el-table
      :data="tableData"
      v-loading="loading"
      border
      stripe
      style="width: 100%"
    >
      <el-table-column type="selection"> </el-table-column>
      <el-table-column prop="id" label="id"> </el-table-column>
      <el-table-column prop="day" label="天数"> </el-table-column>
      <el-table-column prop="money" label="奖励金币数"> </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="primary" @click="OpenEditDialog(scope.row)"
            >编辑</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 编辑弹窗 -->
    <el-dialog title="编辑签到规则" :visible.sync="dialogVisible" width="30%">
      <el-form>
        <el-form-item label="奖励金币数">
          <el-input v-model="editMoney"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { GetSignInList, EditSignIn } from "@/api/api";

export default {
  data() {
    return {
      tableData: [],
      loading: false,
      editId: "",
      editMoney: "",
      dialogVisible: false,
    };
  },
  methods: {
    OpenEditDialog(row) {
      this.editId = row.id;
      this.editMoney = row.money;
      this.dialogVisible = true;
    },
    GetSignInLists() {
      this.loading = true;
      GetSignInList().then((res) => {
        if (res.data.code === 200) {
          this.tableData = res.data.data;
          this.loading = false;
        } else {
          this.loading = false;
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      });
    },
    EditSignIns() {
      const token = localStorage.getItem("token");
      EditSignIn(token, this.editId, this.editMoney).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("编辑成功");
          this.dialogVisible = false;
          this.GetSignInLists();
        } else {
          this.$message.error(res.data.msg);
          this.dialogVisible = false;
        }
      });
    },
  },
  created() {
    this.GetSignInLists();
  },
};
</script>

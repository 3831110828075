<template>
  <div>
    <!-- 标签 -->
    <div style="margin-left: 65px">
      <span
        @click="loadWithDraw1('all'), (this.currentPage = 1)"
        :class="{ active: activeTab === 'all' }"
        class="tab"
        >全部</span
      >
      <span
        @click="loadWithDraw1('waiting'), (this.currentPage = 1)"
        :class="{ active: activeTab === 'waiting' }"
        class="tab"
        >待审核</span
      >
      <span
        @click="loadWithDraw1('passed'), (this.currentPage = 1)"
        :class="{ active: activeTab === 'passed' }"
        class="tab"
        >已审核</span
      >
      <span
        @click="loadWithDraw1('refuse')"
        :class="{ active: activeTab === 'refuse' }"
        class="tab"
        >已拒绝</span
      >
    </div>
    <el-divider />
    <!-- 数据表格 -->
    <el-table :data="tableData" stripe style="width: 100%" v-loading="loading">
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column prop="id" label="提现"></el-table-column>
      <el-table-column prop="info" label="信息"></el-table-column>
      <el-table-column prop="user_id" label="用户id"></el-table-column>
      <el-table-column prop="username" label="用户名"></el-table-column>
      <el-table-column prop="money" label="金额"></el-table-column>
      <el-table-column prop="addtime" label="订单创建时间">
        <template slot-scope="scope">
          <span>{{ formatDate(scope.row.addtime) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="uptime" label="订单回调时间">
        <template slot-scope="scope">
          <span>{{ formatDate(scope.row.uptime) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="bankid" label="银行信息ID"></el-table-column>
      <el-table-column prop="status" label="状态">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.status === 0" type="info">待审核</el-tag>
          <el-tag v-if="scope.row.status === 1" type="success">审核通过</el-tag>
          <el-tag v-if="scope.row.status === 2" type="danger">审核拒绝</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="200">
        <template slot-scope="scope">
          <el-button type="success" @click="openPassDialog(scope.row)"
            >审核</el-button
          >
          <el-popconfirm
            title="请注意一旦删除订单后不可恢复，请谨慎操作。是否真的删除该订单？"
          >
            <el-button type="danger" slot="reference" style="margin-left: 10px"
              >删除</el-button
            >
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页器 -->
    <el-row :gutter="20">
      <el-col :span="12" :offset="0" style="line-height: 60px">
        <span>共 {{ total }} 页</span>
      </el-col>
      <el-col :span="12" :offset="0">
        <div
          style="
            display: flex;
            justify-content: right;
            align-items: right;
            margin-top: 20px;
          "
        >
          <el-button
            round
            style="padding: 10px"
            @click="changePage(currentPage - 1)"
            :disabled="currentPage === 1"
            ><i class="el-icon-arrow-left"></i
          ></el-button>
          <div v-for="page in total" :key="page" style="margin: 0 5px">
            <div
              @click="changePage(page)"
              style="
                background-color: white;
                border: 1px solid #ccc;
                color: black;
                padding: 10px 15px 10px 15px;
                border-radius: 2px;
              "
            >
              {{ page }}
            </div>
          </div>
          <el-button
            round
            style="padding: 10px"
            @click="changePage(currentPage + 1)"
            :disabled="currentPage === total"
            ><i class="el-icon-arrow-right"></i
          ></el-button>
        </div>
      </el-col>
    </el-row>
    <!-- 审核通过弹窗 -->
    <el-dialog title="当前订单信息" :visible.sync="dialogVisible" width="50%">
      <el-row :gutter="20">
        <el-col :span="12" :offset="0">
          <el-form>
            <el-form-item label="订单号">
              <el-input v-model="row.id" disabled></el-input>
            </el-form-item>
            <el-form-item label="用户名">
              <el-input v-model="row.username" disabled></el-input>
            </el-form-item>
            <el-form-item label="金额">
              <el-input v-model="row.money" disabled></el-input>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="12" :offset="0">
          <el-form>
            <el-form-item label="银行信息ID">
              <el-input v-model="row.bankid" disabled></el-input>
            </el-form-item>
            <el-form-item label="状态">
              <el-input
                v-if="row.status == 0"
                value="待审核"
                disabled
              ></el-input>
              <el-input
                v-if="row.status == 1"
                value="审核通过"
                disabled
              ></el-input>
              <el-input v-if="row.status == 2" value="拒绝" disabled></el-input>
            </el-form-item>
            <el-form-item label="订单创建时间">
              <el-input v-model="row.addtime" disabled></el-input>
            </el-form-item>
          </el-form>
          <div v-show="this.type === 2">
            <el-input
              type="textarea"
              :rows="2"
              placeholder="请输入拒绝理由"
              v-model="memo"
            >
            </el-input>
          </div>
        </el-col>
      </el-row>
      <div v-for="item in drawChannelList" :key="item.id">
        <span style="margin-right: 10px">选择提现通道</span>
        <el-radio-group v-model="chanid">
          <el-radio-button :label="item.id">{{ item.title }}</el-radio-button>
        </el-radio-group>
      </div>
      <div style="margin-top: 10px">
        <span style="margin-right: 10px">审核意见</span>
        <el-radio-group v-model="type">
          <el-radio :label="1">同意提现</el-radio>
          <el-radio :label="2">拒绝提现</el-radio>
        </el-radio-group>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="uploadWithdrawAudit(row.orderNo)"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { GetWithdrawList, GetDrawChannelList, WithdrawAudit } from "@/api/api";
import moment from "moment";

export default {
  data() {
    return {
      chanid: "",
      startDate: "",
      endDate: "",
      category: "",
      keywords: "",
      page: 1,
      size: 5,
      total: 0, // 总页数
      currentPage: 1,
      activeTab: "all", // 当前选中的标签
      status: "1",
      status2: "2",
      status3: "3",
      status4: "4",
      type: "",
      memo: "",
      row: "",
      tableData: [],
      dialogVisible: false,
      drawChannelList: [],
      loading: false,
    };
  },
  methods: {
    loadWithDraw(tab) {
      this.activeTab = tab; // 更新当前选中的标签
      switch (tab) {
        case "all":
          this.fetchData();
          break;
        case "waiting":
          this.fetchWaitData();
          break;
        case "passed":
          this.fetchPassedData();
          break;
        case "refuse":
          this.fetchRefuseData();
          break;
      }
    },
    loadWithDraw1(tab) {
      this.currentPage = 1;
      this.activeTab = tab; // 更新当前选中的标签
      switch (tab) {
        case "all":
          this.fetchData();
          break;
        case "waiting":
          this.fetchWaitData();
          break;
        case "passed":
          this.fetchPassedData();
          break;
        case "refuse":
          this.fetchRefuseData();
          break;
      }
    },
    fetchData() {
      const token = localStorage.getItem("token");
      this.loading = true;
      GetWithdrawList(token, this.currentPage, this.size, this.status).then(
        (res) => {
          if (res.data.code === 200) {
            this.tableData = res.data.data.data;
            this.total = res.data.data.total; // 设置总页数
            this.loading = false;
          } else {
            this.$message.error(res.data.msg);
            this.loading = false;
          }
        }
      );
    },
    fetchWaitData() {
      const token = localStorage.getItem("token");
      this.loading = true;
      GetWithdrawList(token, this.currentPage, this.size, this.status2).then(
        (res) => {
          if (res.data.code === 200) {
            this.tableData = res.data.data.data;
            this.total = res.data.data.total; // 设置总页数
            this.loading = false;
          } else {
            this.$message.error(res.data.msg);
            this.loading = false;
          }
        }
      );
    },
    fetchPassedData() {
      const token = localStorage.getItem("token");
      this.loading = true;
      GetWithdrawList(token, this.currentPage, this.size, this.status3).then(
        (res) => {
          if (res.data.code === 200) {
            this.tableData = res.data.data.data;
            this.total = res.data.data.total; // 设置总页数
            this.loading = false;
          } else {
            this.$message.error(res.data.msg);
            this.loading = false;
          }
        }
      );
    },
    fetchRefuseData() {
      const token = localStorage.getItem("token");
      this.loading = true;
      GetWithdrawList(token, this.currentPage, this.size, this.status4).then(
        (res) => {
          if (res.data.code === 200) {
            this.tableData = res.data.data.data;
            this.total = res.data.data.total; // 设置总页数
            this.loading = false;
          } else {
            this.$message.error(res.data.msg);
            this.loading = false;
          }
        }
      );
    },
    uploadWithdrawAudit(orderNo) {
      const token = localStorage.getItem("token");
      WithdrawAudit(token, this.chanid, orderNo, this.type, this.memo).then(
        (res) => {
          if (res.data.code === 200) {
            this.$message.success(res.data.msg);
            this.fetchWaitData();
          } else {
            this.$message.error(res.data.msg);
          }
        }
      );
    },
    changePage(page) {
      if (page < 1 || page > this.total) return;
      this.currentPage = page;
      this.loadWithDraw(this.activeTab);
    },
    openPassDialog(row) {
      this.row = row;
      this.dialogVisible = true;
      this.GetDrawChannelList();
    },
    GetDrawChannelList() {
      GetDrawChannelList().then((res) => {
        if (res.data.code === 200) {
          this.drawChannelList = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    formatDate(date) {
      return moment.unix(date).format("YYYY-MM-DD HH:mm:ss");
    },
  },
  created() {
    this.fetchData();
  },
};
</script>

<style>
.tab {
  cursor: pointer;
  margin-right: 15px;
  color: black;
}
.tab.active {
  color: #409eff;
}
.el-divider--horizontal {
  display: block;
  height: 1px;
  width: 100%;
  margin: 10px 0 !important;
}
</style>

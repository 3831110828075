<template>
  <div>
    <!-- 数据展示表格 -->
    <el-table :data="tableData" v-loading="loading" stripe style="width: 100%">
      <el-table-column type="selection"> </el-table-column>
      <el-table-column prop="id" label="用户ID"> </el-table-column>
      <el-table-column prop="signcount" label="连续签到天数"> </el-table-column>
      <el-table-column prop="signsum" label="签到领取金币数"> </el-table-column>
      <el-table-column prop="creattime" label="签到时间">
        <template slot-scope="scope">
          {{ formatDate(scope.row.creattime) }}
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页器 -->
    <el-row :gutter="20">
      <el-col :span="12" :offset="0" style="line-height: 60px">
        <span>共 {{ total }} 页</span>
      </el-col>
      <el-col :span="12" :offset="0">
        <div
          style="
            display: flex;
            justify-content: right;
            align-items: right;
            margin-top: 20px;
          "
        >
          <el-button
            round
            style="padding: 10px"
            @click="changePage(currentPage - 1)"
            :disabled="currentPage === 1"
            ><i class="el-icon-arrow-left"></i
          ></el-button>
          <div v-for="page in total" :key="page" style="margin: 0 5px">
            <div
              @click="changePage(page)"
              style="
                background-color: white;
                border: 1px solid #ccc;
                color: black;
                padding: 10px 15px 10px 15px;
                border-radius: 2px;
              "
            >
              {{ page }}
            </div>
          </div>
          <el-button
            round
            style="padding: 10px"
            @click="changePage(currentPage + 1)"
            :disabled="currentPage === total"
            ><i class="el-icon-arrow-right"></i
          ></el-button>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { GetSignInRecord } from "@/api/api";
import moment from "moment";

export default {
  data() {
    return {
      tableData: [],
      loading: false,
      page: 1,
      list: 10,
      total: 0,
    };
  },
  methods: {
    //时间格式化
    formatDate(date) {
      return moment.unix(date).format("YYYY-MM-DD HH:mm:ss");
    },
    GetSignInRecords() {
      const token = localStorage.getItem("token");
      this.loading = true;
      GetSignInRecord(token, this.page, this.list).then((res) => {
        if (res.data.code === 200) {
          this.tableData = res.data.data.data;
          this.total = res.data.data.total; // 设置总页数
          this.loading = false;
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
          this.loading = false;
        }
      });
    },
    changePage(page) {
      if (page < 1 || page > this.total) return;
      this.currentPage = page;
      this.loadWithDraw(this.activeTab);
    },
  },
  created() {
    this.GetSignInRecords();
  },
};
</script>

<template>
  <div>
    <!-- 数据展示表格 -->
    <el-table :data="tableData" stripe style="width: 100%">
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column prop="id" label="日期" width="55"></el-table-column>
      <el-table-column prop="channel" label="渠道代码"></el-table-column>
      <el-table-column prop="ip" label="ip"></el-table-column>
      <el-table-column prop="event" label="事件"></el-table-column>
      <el-table-column prop="createtime" label="创建时间">
        <template slot-scope="scope">
          {{ formatDate(scope.row.createtime) }}
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页器 -->
    <el-pagination
      background
      layout="prev, pager, next"
      :total="totalPages"
      :current-page="page"
      @current-change="handlePageChange"
      style="position: fixed; right: 0"
    >
    </el-pagination>
  </div>
</template>

<script>
import { GetAdvertisementRecord } from "@/api/api";
import moment from "moment";

export default {
  data() {
    return {
      tableData: [],
      page: 1,
      list: 10,
      totalPages: 0, // 新增一个变量来存储总页数
    };
  },
  methods: {
    formatDate(date) {
      return moment.unix(date).format("YYYY-MM-DD HH:mm:ss");
    },
    handlePageChange(newPage) {
      this.page = newPage; // 更新当前页码
      this.GetAdvertisement(); // 重新加载数据
    },
    GetAdvertisement() {
      GetAdvertisementRecord(this.page, this.list).then((res) => {
        if (res.data.code === 200) {
          this.tableData = res.data.data.data;
          this.totalPages = res.data.data.total; // 更新总页数
        } else {
          this.$message({
            message: res.data.msg,
            type: "warning",
          });
        }
      });
    },
  },
  created() {
    this.GetAdvertisement(); // 初始加载数据
  },
};
</script>

<template>
  <div>
    <!-- 条件搜索 -->
    <div>
      <el-date-picker
        v-model="startDate"
        align="right"
        type="date"
        placeholder="选择起始日期"
        value-format="yyyy-MM-dd"
        :picker-options="pickerOptions"
        style="margin-left: 10px"
      >
      </el-date-picker>
      <el-date-picker
        v-model="endDate"
        align="right"
        type="date"
        placeholder="选择结束日期"
        value-format="yyyy-MM-dd"
        :picker-options="pickerOptions"
        style="margin-left: 10px"
      >
      </el-date-picker>
      <el-cascader
        v-model="category"
        :options="options"
        @change="handleChange"
        style="margin-left: 10px"
      ></el-cascader>
      <el-input
        v-model="keywords"
        placeholder="请输入你要搜索的内容"
        style="width: 300px; margin-left: 10px"
      ></el-input>
      <el-button @click="fetchData" type="primary" style="margin-left: 10px"
        >搜索</el-button
      >
    </div>
    <!-- 数据展示表格 -->
    <el-table :data="data" border stripe style="width: 100%; margin-top: 10px">
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column prop="id" label="id"> </el-table-column>
      <el-table-column prop="info" label="信息"> </el-table-column>
      <el-table-column prop="user_id" label="用户ID"> </el-table-column>
      <el-table-column prop="username" label="用户名"> </el-table-column>
      <el-table-column prop="money" label="金额"> </el-table-column>
      <el-table-column prop="dai_id" label="上级ID"> </el-table-column>
      <el-table-column prop="addtime" label="添加时间"> </el-table-column>
      <el-table-column label="操作" width="200">
        <template slot-scope="scope">
          <el-button type="primary" @click="openInfomationDialog(scope.row)"
            >查看详情</el-button
          >
          <el-popconfirm
            title="请注意一旦删除订单后不可恢复，请谨慎操作。是否真的删除该订单？"
            @confirm="deleteOrder(scope.row.orderNo)"
          >
            <el-button type="danger" slot="reference" style="margin-left: 10px">
              <i class="fa fa-trash"> </i>
            </el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页器 -->
    <el-pagination
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="page"
      :page-sizes="[5, 10, 20, 30]"
      :page-size="size"
      layout="sizes, prev, pager, next"
      :total="total"
      style="margin-top: 20px; text-align: right"
    >
    </el-pagination>
    <!-- 查看详情弹窗 -->
    <el-dialog
      title="查看详情"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <p><b>用户id：</b>{{ info.user_id }}</p>
      <p><b>用户名：</b>{{ info.username }}</p>
      <p><b>信息：</b>{{ info.info }}</p>
      <p><b>金额：</b>{{ info.money }}</p>
      <p><b>添加时间：</b>{{ info.addtime }}</p>
      <p><b>上级ID：</b>{{ info.dai_id }}</p>
      <p><b>订单号：</b>{{ info.orderNo }}</p>
      <p>
        <b>状态：</b>
        <span v-if="info.status == 0" style="color: red">待支付</span>
        <span v-else-if="info.status == 1" style="color: green">已支付</span>
      </p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { GetOrder, RechargeList, DeleteOrder } from "@/api/api";
import moment from "moment";

export default {
  data() {
    return {
      dialogVisible: false,
      startDate: "",
      endDate: "",
      category: "",
      keywords: "",
      orderNo: "",
      type: "",
      info: "",
      data: [],
      page: 1,
      size: 10,
      total: 0,
      options: [
        {
          value: "1",
          label: "按照用户名搜索",
        },
        {
          value: "2",
          label: "按照订单号搜索",
        },
      ],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
    };
  },
  methods: {
    formatAddTime(data) {
      return Array.isArray(data)
        ? data.map((item) => {
            // 使用 "YYYY-MM-DD HH:mm:ss" 格式
            return {
              ...item,
              addtime: moment.unix(item.addtime).format("YYYY-MM-DD HH:mm:ss"),
            };
          })
        : [];
    },
    openInfomationDialog(row) {
      this.orderNo = row.orderNo;
      this.type = row.type;
      this.dialogVisible = true;
      this.getInfomation();
    },
    getInfomation() {
      const token = localStorage.getItem("token"); // 取出 token
      GetOrder(token, this.orderNo, this.type).then((res) => {
        if (res.data.code === 200) {
          // 假设 res.data.data 是一个对象而非数组，单独格式化 addtime
          this.info = {
            ...res.data.data,
            addtime: moment
              .unix(res.data.data.addtime)
              .format("YYYY-MM-DD HH:mm:ss"),
          };
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    deleteOrder(orderNo) {
      const token = localStorage.getItem("token"); // 取出 token
      DeleteOrder(token, orderNo).then((res) => {
        if (res.data.code === 200) {
          this.$message.success(res.data.msg);
          this.dialogVisible = false;
          this.fetchData();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    fetchData() {
      const token = localStorage.getItem("token"); // 取出 token
      RechargeList(token, this.page, this.size).then((res) => {
        if (res.data.code == 200) {
          this.data = this.formatAddTime(res.data.data.data);
          this.total = res.data.data.total; // 假设后端返回总数
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    handleSizeChange(size) {
      this.size = size;
      this.page = 1; // 当改变每页显示数时，重置到第一页
      this.fetchData();
    },
    handleCurrentChange(page) {
      this.page = page;
      this.fetchData();
    },
  },
  created() {
    this.fetchData();
  },
};
</script>

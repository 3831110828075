import Vue from "vue";
import VueRouter from "vue-router";
import App from "./App.vue";
import axios from "axios";
import router from "./router/router";
import ElementUI from "element-ui";
import VueCodemirror from "vue-codemirror";
import TDesign from "tdesign-vue";
import "element-ui/lib/theme-chalk/index.css";
import "font-awesome/css/font-awesome.min.css";
import "tdesign-vue/es/style/index.css";
import "codemirror/mode/javascript/javascript.js"; // 根据需要引入其他语言支持
import 'codemirror/lib/codemirror.css'; // 引入 CodeMirror 的基础样式
import "codemirror/theme/monokai.css"; // 主题样式（可选）

Vue.config.productionTip = false;

Vue.use(VueRouter);
Vue.use(TDesign);
Vue.use(ElementUI);
Vue.use(VueCodemirror); // 使用 vue-codemirror

// 配置axios全局使用
Vue.prototype.$http = axios;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");

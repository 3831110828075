<template>
  <div id="app">
    <!-- <router-view /> -->
    <el-row>
      <el-col span="4">
        <!-- 左侧菜单栏 -->
        <el-menu
          default-active="1"
          class="el-menu-vertical-demo"
          @open="handleOpen"
          @close="handleClose"
        >
          <img src="@/assets/logo.png" alt="logo" style="width: 100%" />
          <el-menu-item index="1" @click="open(1)">
            <i class="fa fa-dashboard" style="margin: 10px"></i>
            <span slot="title">控制台</span>
          </el-menu-item>
          <el-menu-item index="2" @click="open(2)">
            <i class="fa fa-info-circle" style="margin: 10px"></i>
            <span slot="title">会员信息管理</span>
          </el-menu-item>
          <el-submenu index="3">
            <template slot="title">
              <i class="fa fa-building" style="margin: 10px"></i>
              <span>三方游戏管理</span>
            </template>
            <el-menu-item-group>
              <template slot="title">游戏管理二级菜单</template>
              <el-menu-item index="8-1" @click="open(31)"
                >三方游戏管理</el-menu-item
              >
              <el-menu-item index="8-2" @click="open(32)"
                >三方游戏历史记录</el-menu-item
              >
            </el-menu-item-group>
          </el-submenu>
          <el-menu-item index="4" @click="open(4)">
            <i class="fa fa-video-camera" style="margin: 10px"></i>
            <span slot="title">视频管理</span>
          </el-menu-item>
          <el-menu-item index="5" @click="open(5)">
            <i class="fa fa-image" style="margin: 10px"></i>
            <span slot="title">轮播管理</span>
          </el-menu-item>
          <el-menu-item index="6" @click="open(6)">
            <i class="fa fa-list-ul" style="margin: 10px"></i>
            <span slot="title">活动管理</span>
          </el-menu-item>
          <el-submenu index="7">
            <template slot="title">
              <i class="fa fa-money" style="margin: 10px"></i>
              <span>财务管理</span>
            </template>
            <el-menu-item-group>
              <template slot="title">财务管理菜单</template>
              <el-menu-item index="7-1" @click="open(71)"
                >充值管理</el-menu-item
              >
              <el-menu-item index="7-2" @click="open(72)"
                >提现管理</el-menu-item
              >
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="8">
            <template slot="title">
              <i class="fa fa-building" style="margin: 10px"></i>
              <span>联运公司管理</span>
            </template>
            <el-menu-item-group>
              <template slot="title">联运公司二级菜单</template>
              <el-menu-item index="8-1" @click="open(81)"
                >联运公司管理</el-menu-item
              >
              <el-menu-item index="8-2" @click="open(82)"
                >联运公司员工管理</el-menu-item
              >
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="9">
            <template slot="title">
              <i class="fa fa-tv" style="margin: 10px"></i>
              <span>广告管理</span>
            </template>
            <el-menu-item-group>
              <template slot="title">广告管理二级菜单</template>
              <el-menu-item index="8-1" @click="open(91)"
                >渠道管理</el-menu-item
              >
              <el-menu-item index="8-2" @click="open(92)"
                >打点记录</el-menu-item
              >
            </el-menu-item-group>
          </el-submenu>
          <el-menu-item index="10" @click="open(10)">
            <i class="fa fa-gear" style="margin: 10px"></i>
            <span slot="title">全局配置</span>
          </el-menu-item>
          <el-submenu index="11">
            <template slot="title">
              <i class="fa fa-user" style="margin: 10px"></i>
              <span>客服管理</span>
            </template>
            <el-menu-item-group>
              <template slot="title">客服管理二级菜单</template>
              <el-menu-item index="8-1" @click="open(111)"
                >客服分类管理</el-menu-item
              >
              <el-menu-item index="8-2" @click="open(112)"
                >客服成员管理</el-menu-item
              >
            </el-menu-item-group>
          </el-submenu>
          <el-menu-item index="12" @click="open(12)">
            <i class="fa fa-sticky-note-o" style="margin: 10px"></i>
            <span slot="title">站点公告管理</span>
          </el-menu-item>
          <el-submenu index="13">
            <template slot="title">
              <i class="fa fa-users" style="margin: 10px"></i>
              <span>签到管理</span>
            </template>
            <el-menu-item-group>
              <template slot="title">签到管理二级菜单</template>
              <el-menu-item index="13-1" @click="open(131)"
                >签到规则管理</el-menu-item
              >
              <el-menu-item index="13-2" @click="open(132)"
                >玩家签到记录</el-menu-item
              >
            </el-menu-item-group>
          </el-submenu>
        </el-menu>
      </el-col>
      <div>
        <el-popover trigger="hover">
          <p>当前登录用户:{{ username }}</p>
          <el-button
            type="success"
            @click="logout()"
            style="width: 100%; float: right"
            >清除登录</el-button
          >
          <el-button
            type="danger"
            style="width: 100%; margin-top: 10px; float: right"
            @click="openChangeDialog()"
            >修改密码</el-button
          >
          <el-image
            :src="avatar"
            alt="avatar"
            slot="reference"
            style="
              width: 70px;
              height: 70px;
              float: right;
              border-radius: 100px;
            "
          ></el-image>
        </el-popover>
      </div>
      <el-col span="20">
        <el-tabs
          v-if="isShow == true"
          v-model="editableTabsValue"
          type="card"
          editable
          @edit="handleTabsEdit"
        >
          <el-tab-pane
            :key="item.name"
            v-for="item in editableTabs"
            :label="item.title"
            :name="item.name"
          >
            <component :is="item.component"></component>
          </el-tab-pane>
        </el-tabs>
      </el-col>
    </el-row>
    <!-- 修改密码弹窗 -->
    <el-dialog title="修改密码" :visible.sync="dialogVisible" width="30%">
      <el-form>
        <el-form-item label="请输入原密码"
          ><el-input v-model="oldPassword"></el-input
        ></el-form-item>
        <el-form-item label="请输入新密码"
          ><el-input v-model="newPassword"></el-input
        ></el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="ChangePwd">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import DashBoard from "@/components/DashBoard.vue";
import ActivityManage from "@/components/ActivityManage.vue";
import GameManage from "@/components/GameManage.vue";
import SwipeManage from "@/components/SwipeManage.vue";
import TopupManage from "@/components/TopupManage.vue";
import VideoManage from "@/components/VideoManage.vue";
import VipInfomation from "@/components/VipInfomation.vue";
import WithdrawManage from "@/components/WithdrawManage.vue";
import LoginPage from "@/components/LoginPage.vue";
import GameHistory from "./GameHistory.vue";
import JoinOpreationManageVue from "@/components/JoinOpreationManage.vue";
import JoinOpreationStaffManageVue from "@/components/JoinOpreationStaffManage.vue";
import GlobalConfig from "./GlobalConfig.vue";
import { Logout, ChangePassword } from "@/api/api";
import { Message } from "element-ui";
import AdvertiseManage from "./AdvertiseManage.vue";
import ChannelManage from "./ChannelManage.vue";
import ServeManage from "./ServeManage.vue";
import ServeList from "./ServeList.vue";
import NoticeManage from "./NoticeManage.vue";
import SignRule from "./SignRule.vue";
import UserSign from "./UserSign.vue";

export default {
  data() {
    return {
      dialogVisible: false,
      isCollapse: false,
      username: "",
      avatar: "",
      oldPassword: "",
      newPassword: "",
      isLoggedIn: localStorage.getItem("isLoggedIn"),
      isShow: true,
      editableTabs: [], // 标签选项卡列表
      editableTabsValue: "1", // 默认选中的标签选项卡
      currentView: "DashBoard",
      componentsList: {
        1: "DashBoard",
        2: "VipInfomation",
        31: "GameManage",
        32: "GameHistory",
        4: "VideoManage",
        5: "SwipeManage",
        6: "ActivityManage",
        71: "TopupManage",
        72: "WithdrawManage",
        81: "JoinOpreationManageVue",
        82: "JoinOpreationStaffManageVue",
        91: "AdvertiseManage",
        92: "ChannelManage",
        10: "GlobalConfig",
        111: "ServeManage",
        112: "ServeList",
        12: "NoticeManage",
        131: "SignRule",
        132: "UserSign",
      },
    };
  },
  components: {
    DashBoard,
    ActivityManage,
    GameManage,
    SwipeManage,
    TopupManage,
    VideoManage,
    VipInfomation,
    WithdrawManage,
    LoginPage,
    JoinOpreationManageVue,
    JoinOpreationStaffManageVue,
    GameHistory,
    AdvertiseManage,
    ChannelManage,
    GlobalConfig,
    ServeManage,
    ServeList,
    NoticeManage,
    SignRule,
    UserSign,
  },
  computed: {
    isLogin() {
      return this.isLoggedIn === "true"; // 判断是否登录
    },
  },
  methods: {
    test() {
      this.isCollapse = !this.isCollapse;
      this.$message.success(this.isCollapse);
    },
    openChangeDialog() {
      this.dialogVisible = true;
    },
    ChangePwd() {
      const token = localStorage.getItem("token"); // 取出 token
      ChangePassword(token, this.oldPassword, this.newPassword).then((res) => {
        if (res.data.code == 200) {
          Message({
            message: "修改密码成功",
            type: "success",
          });
          this.dialogVisible = false;
        } else {
          Message({
            message: res.data.msg,
            type: "error",
          });
        }
      });
    },
    logout() {
      const token = localStorage.getItem("token"); // 取出 token
      Logout(token).then((res) => {
        if (res.data.data == 200) {
          this.$message({
            message: "退出登录成功，即将刷新页面",
            type: "success",
            // 清空本地存储
            onClose: () => {
              localStorage.removeItem("isLoggedIn");
              localStorage.removeItem("token");
              localStorage.removeItem("username");
              localStorage.removeItem("avatar");
              window.location.reload(); // 刷新页面
            },
          });
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      });
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    open(code) {
      // 检查是否已经存在相同的标签页
      let existingTab = this.editableTabs.find(
        (tab) => tab.name === this.componentsList[code]
      );
      if (!existingTab) {
        // 如果不存在，则添加新的标签页
        this.currentView = this.componentsList[code];
        let menuName = {
          1: "控制台",
          2: "会员信息管理",
          31: "三方游戏管理",
          32: "三方游戏历史",
          4: "视频管理",
          5: "轮播管理",
          6: "活动管理",
          71: "充值管理",
          72: "提现管理",
          81: "联运公司管理",
          82: "联运公司员工管理",
          91: "渠道管理",
          92: "打点记录",
          10: "全局配置",
          111: "客服分类管理",
          112: "客服成员管理",
          12: "站点公告管理",
          131: "签到规则管理",
          132: "玩家签到记录",
        };
        this.editableTabs.push({
          title: menuName[code],
          name: this.componentsList[code], // 使用组件键作为标签页的名称
          component: this.componentsList[code],
        });
        this.editableTabsValue = this.componentsList[code];
      } else {
        // 如果存在，则切换到已存在的标签页
        this.editableTabsValue = existingTab.name; // 修改这里
      }
    },

    handleTabsEdit(targetName, action) {
      if (action === "add") {
        let newTabName = ++this.editableTabs.length;
        this.editableTabs.push({
          title: "New Tab", // 这里可以根据需要设置新的标签页标题
          name: newTabName,
          component: "NewTabComponent", // 你需要替换为实际的组件名称
        });
        this.editableTabsValue = newTabName;
      }
      if (action === "remove") {
        let tabs = this.editableTabs;
        let activeName = this.editableTabsValue;
        if (activeName === targetName) {
          tabs.forEach((tab, index) => {
            if (tab.name === targetName) {
              let nextTab = tabs[index + 1] || tabs[index - 1];
              if (nextTab) {
                activeName = nextTab.name;
              }
            }
          });
        }
        this.editableTabsValue = activeName;
        this.editableTabs = tabs.filter((tab) => tab.name !== targetName);
      }
    },
  },
  created() {
    this.isLoggedIn = localStorage.getItem("isLoggedIn"); // 从localStorage中获取登录状态
    this.username = localStorage.getItem("username"); // 取出 username
    this.avatar = localStorage.getItem("avatar"); // 取出用户头像
    if (this.isLoggedIn == null) {
      this.currentView = "LoginPage"; // 如果未登录，就跳转到登录页面
      this.isShow = false;
    } else {
      this.isShow = true;
      this.open(1);
    }
  },
  watch: {
    isLoggedIn(val) {
      if (!val) {
        this.currentView = "LoginPage"; // 如果未登录，就跳转到登录页面
      }
    },
  },
};
</script>

<style></style>

<template>
  <div>
    <div>
      <el-button
        style="margin-left: 10px"
        type="success"
        @click="dialogVisible = true"
        >添加客服成员</el-button
      >
    </div>
    <!-- 数据展示表格 -->
    <el-table :data="tableData" stripe style="width: 100%">
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column prop="id" label="id"> </el-table-column>
      <el-table-column prop="username" label="用户名"> </el-table-column>
      <el-table-column prop="typeid" label="分类id"> </el-table-column>
      <el-table-column prop="url" label="跳转Url"></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="primary" @click="OpenEditDialog(scope.row)"
            >编辑</el-button
          >
          <el-popconfirm
            title="删除后客服数据无法恢复！确认删除吗"
            @confirm="DeleteServeMembers(scope.row.id)"
          >
            <el-button slot="reference" type="danger" style="margin-left: 10px"
              >删除</el-button
            >
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <!-- 添加客服成员dialog -->
    <el-dialog title="添加客服成员" :visible.sync="dialogVisible" width="30%">
      <el-form>
        <el-form-item label="请输入客服分类ID">
          <el-input v-model="addCategoryId"></el-input>
        </el-form-item>
        <el-form-item label="请输入客服成员标题">
          <el-input v-model="addUserName"></el-input>
        </el-form-item>
        <el-form-item label="请输入转跳URL">
          <el-input v-model="addUrl"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="AddServeMembers()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 编辑弹窗 -->
    <el-dialog
      title="编辑客服成员"
      :visible.sync="EditdialogVisible"
      width="30%"
    >
      <el-form>
        <el-form-item label="请输入客服ID">
          <el-input v-model="EditId"></el-input>
        </el-form-item>
        <el-form-item label="请输入客服分类ID">
          <el-input v-model="EditCategoryId"></el-input>
        </el-form-item>
        <el-form-item label="请输入客服成员标题">
          <el-input v-model="EditUserName"></el-input>
        </el-form-item>
        <el-form-item label="请输入转跳URL">
          <el-input v-model="EditUrl"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="EditdialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="UpdateServeMembers()"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  GetServeMemberList,
  AddServeMember,
  DeleteServeMember,
  UpdateServeMember,
} from "@/api/api";

export default {
  data() {
    return {
      tableData: [],
      dialogVisible: false,
      EditdialogVisible: false,
      addCategoryId: "",
      addUserName: "",
      addUrl: "",
      EditId: "",
      EditCategoryId: "",
      EditUserName: "",
      EditUrl: "",
    };
  },
  methods: {
    GetServeMemberLists() {
      GetServeMemberList().then((res) => {
        if (res.data.code === 200) {
          this.tableData = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    AddServeMembers() {
      const token = localStorage.getItem("token");
      AddServeMember(
        token,
        this.addCategoryId,
        this.addUserName,
        this.addUrl
      ).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("添加成功");
          this.GetServeMemberLists();
          this.dialogVisible = false;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    DeleteServeMembers(id) {
      const token = localStorage.getItem("token");
      DeleteServeMember(token, id).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("删除成功");
          this.GetServeMemberLists();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    UpdateServeMembers() {
      const token = localStorage.getItem("token");
      UpdateServeMember(
        token,
        this.EditId,
        this.EditCategoryId,
        this.EditUserName,
        this.EditUrl
      ).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("修改成功");
          this.GetServeMemberLists();
          this.EditdialogVisible = false;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    OpenAddDialog() {
      this.dialogVisible = true;
    },
    OpenEditDialog(row) {
      this.row = row;
      this.EditId = row.id;
      this.EditCategoryId = row.typeid;
      this.EditUserName = row.username;
      this.EditUrl = row.url;
      this.EditdialogVisible = true;
    },
  },
  created() {
    this.GetServeMemberLists();
  },
};
</script>

<template>
  <div>
    <!-- 数据展示表格 -->
    <el-table :data="data" border style="width: 100%">
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column prop="id" label="id" width="50"> </el-table-column>
      <el-table-column prop="username" label="用户名"> </el-table-column>
      <el-table-column prop="nickname" label="昵称"> </el-table-column>
      <el-table-column label="头像">
        <template slot-scope="scope">
          <el-avatar :size="50" :src="scope.row.avatar"></el-avatar>
        </template>
      </el-table-column>
      <el-table-column prop="logintime" label="登录时间">
        <template slot-scope="scope">
          {{ formatDate(scope.row.logintime) }}
        </template>
      </el-table-column>
      <el-table-column prop="loginip" label="登录IP"> </el-table-column>
      <el-table-column prop="jointime" label="加入时间">
        <template slot-scope="scope">
          {{ formatDate(scope.row.jointime) }}
        </template>
      </el-table-column>
      <el-table-column prop="joinip" label="加入IP"> </el-table-column>
      <el-table-column prop="ch" label="广告渠道"> </el-table-column>
      <el-table-column prop="status" label="状态">
        <template slot-scope="scope">
          <span :class="getStatusClass(scope.row.status)">{{
            getStatusText(scope.row.status)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="200">
        <template slot-scope="scope">
          <el-button type="primary" @click="GetMember(scope.row.id)"
            >我的下级</el-button
          >
          <el-button type="warning" @click="openEditDialog(scope.row.id)">
            <i class="fa fa-edit"></i>
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页器 -->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      background
      layout="prev, pager, next"
      :total="total"
      style="margin-top: 20px; text-align: right"
    >
    </el-pagination>
    <!-- 用户编辑弹窗 -->
    <el-dialog title="编辑用户" :visible.sync="EditDialogVisible" width="50%">
      <el-row :gutter="20">
        <el-col :span="12" :offset="0">
          <el-form>
            <el-form-item label="用户名">
              <el-input
                v-model="changeData.username"
                :value="userinfo.username"
              ></el-input>
            </el-form-item>
            <el-form-item label="昵称">
              <el-input
                v-model="changeData.nickname"
                :value="userinfo.nickname"
              ></el-input>
            </el-form-item>
            <el-form-item label="邮箱">
              <el-input
                v-model="changeData.email"
                :value="userinfo.email"
              ></el-input>
            </el-form-item>
            <el-form-item label="等级">
              <el-input
                v-model="changeData.level"
                :value="userinfo.level"
              ></el-input>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="12" :offset="0">
          <p>头像：</p>
          <el-image
            style="width: 100px; height: 100px"
            :src="userinfo.avatar"
          ></el-image>
          <el-form>
            <el-form-item label="三方余额">
              <el-input
                v-model="changeData.third_money"
                :value="userinfo.third_money"
              ></el-input>
            </el-form-item>
            <el-form-item label="余额">
              <el-input
                v-model="changeData.money"
                :value="userinfo.money"
              ></el-input>
            </el-form-item>
            <el-form-item label="积分">
              <el-input
                v-model="changeData.score"
                :value="userinfo.score"
              ></el-input>
            </el-form-item>
          </el-form>
          <br />
          <span>修改状态：</span>
          <el-select
            v-model="changeData.status"
            :value="userinfo.status"
            placeholder="请选择"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-col>
      </el-row>
      <!-- 其他用来编辑数据的输入字段或按钮 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="EditDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="uploadEditMember()">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { GetMembersList, viewMember, editMember } from "@/api/api";
import moment from "moment";

export default {
  data() {
    return {
      EditDialogVisible: false,
      selectedRow: {},
      data: [],
      page: 1, // 当前页码
      size: 5, // 每页显示的记录数
      total: 0, // 总记录数
      userinfo: "",
      changeData: {},
      options: [
        {
          value: "normal",
          label: "正常",
        },
        {
          value: "ban",
          label: "封禁",
        },
      ],
      value: "",
    };
  },
  methods: {
    test() {
      if (this.changeData.status === "normal") {
        this.$message.success(this.changeData.status);
      } else {
        this.$message.warning(this.changeData.status);
      }
    },
    formatDate(date) {
      return moment.unix(date).format("YYYY-MM-DD HH:mm:ss");
    },
    uploadEditMember() {
      const token = localStorage.getItem("token"); // 取出 token
      this.changeData.status = this.value;
      editMember(token, this.id, this.changeData).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("编辑成功");
          this.fetchData();
          this.EditDialogVisible = false;
        } else {
          this.$message.error(res.data.msg);
          this.EditDialogVisible = false;
        }
      });
    },
    GetMember(userid) {
      const token = localStorage.getItem("token"); // 取出 token
      viewMember(token, userid).then((res) => {
        if (res.data.code === 200) {
          this.userinfo = res.data.data;
          // 初始化changeData
          this.changeData = { ...this.userinfo };
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    openEditDialog(userid) {
      this.EditDialogVisible = true;
      this.id = userid;
      this.GetMember(userid);
    },
    getStatusClass(status) {
      return status === "normal" ? "normal" : "abnormal";
    },
    getStatusText(status) {
      return status === "normal" ? "• 正常" : "• 被封禁";
    },
    handleSizeChange(newSize) {
      this.size = newSize;
      this.page = 1; // 重置到第一页
      this.fetchData(); // 重新获取数据
    },
    handleCurrentChange(newPage) {
      this.page = newPage;
      this.fetchData(); // 重新获取数据
    },
    fetchData() {
      const token = localStorage.getItem("token"); // 取出 token
      GetMembersList(token, this.page, this.size).then((res) => {
        if (res.data.code === 200) {
          this.data = res.data.data.data; // 你的数据列表
          this.$nextTick(() => {
            this.total = res.data.data.total; // 总记录数
          });
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
  },
  created() {
    this.fetchData();
  },
};
</script>

<style>
.normal {
  color: green;
}

.abnormal {
  color: red;
}
</style>

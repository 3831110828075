<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  components: {},

  created() {},
};
</script>

<style></style>

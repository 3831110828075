<template>
  <div>
    <el-table :data="tableData" border stripe style="width: 100%">
      <el-table-column type="selection"> </el-table-column>
      <el-table-column prop="id" label="id" width="55"> </el-table-column>
      <el-table-column prop="bet_id" label="订单号"> </el-table-column>
      <el-table-column prop="uid" label="用户ID"> </el-table-column>
      <el-table-column prop="game_type_name" label="平台名称">
      </el-table-column>
      <el-table-column prop="bet_amount" label="投注额"> </el-table-column>
      <el-table-column prop="is_hot" label="热门游戏"> </el-table-column>
      <el-table-column prop="valid_bet_amount" label="有效投注额">
      </el-table-column>
      <el-table-column prop="net_amount" label="输赢金额"> </el-table-column>
      <el-table-column prop="pumping_amount" label="抽水"> </el-table-column>
      <el-table-column prop="create_at" label="开始时间"> </el-table-column>
      <el-table-column prop="net_at" label="结束时间"> </el-table-column>
      <el-table-column prop="is_fl" label="是否反水">
        <template slot-scope="scope">
          <span v-if="scope.row.is_fl == 0">否</span>
          <span v-else>是</span>
        </template>
      </el-table-column>
      <el-table-column prop="is_me" label="个人是否反水">
        <template slot-scope="scope">
          <span v-if="scope.row.is_fl == 0">否</span>
          <span v-else>是</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="200">
        <el-button type="primary">编辑</el-button>
        <el-button type="danger">删除</el-button>
      </el-table-column>
    </el-table>
    <!-- 分页器 -->
    <el-pagination
      background
      layout="prev, pager, next"
      :total="totalPages"
      :current-page="page"
      @current-change="handlePageChange"
    >
    </el-pagination>
  </div>
</template>

<script>
import { GetGameHistory } from "@/api/api";

export default {
  data() {
    return {
      tableData: [],
      page: 1,
      list: 5,
      totalPages: 0, // 新增一个变量来存储总页数
    };
  },
  methods: {
    GetGameHistorys() {
      const token = localStorage.getItem("token"); // 取出 token
      GetGameHistory(token, this.page, this.list).then((res) => {
        if (res.data.code === 200) {
          this.tableData = res.data.data.data;
          this.totalPages = res.data.data.total; // 更新总页数
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    handlePageChange(newPage) {
      this.page = newPage; // 更新当前页码
      this.GetGameHistorys(); // 重新加载数据
    },
  },
  created() {
    this.GetGameHistorys();
  },
};
</script>

<template>
  <div>
    <div style="display: flex; margin-left: 10px">
      <el-button type="primary" @click="addDialogVisible = true"
        >添加</el-button
      >
      <el-button>批量删除</el-button>
      <div style="margin-left: 300px">
        <el-form>
          <el-date-picker
            v-model="startDate"
            align="right"
            type="date"
            placeholder="选择起始日期"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptions"
            style="margin-left: 10px"
          >
          </el-date-picker>
          <el-date-picker
            v-model="endDate"
            align="right"
            type="date"
            placeholder="选择结束日期"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptions"
            style="margin-left: 10px"
          >
          </el-date-picker>
          <el-button type="primary" style="margin-left: 10px">搜索</el-button>
        </el-form>
      </div>
    </div>
    <!-- 数据展示表格 -->
    <el-table :data="displayedData" stripe style="width: 100%">
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column prop="id" label="id"> </el-table-column>
      <el-table-column prop="videoUrl" label="视频源URL"> </el-table-column>
      <el-table-column prop="playTime" label="播放时长"> </el-table-column>
      <el-table-column prop="needUnlockTime" label="需要解锁时间">
      </el-table-column>
      <el-table-column prop="playNumber" label="播放量"> </el-table-column>
      <el-table-column prop="uploadTime" label="上传日期"> </el-table-column>
      <el-table-column prop="videoTitle" label="视频标题"> </el-table-column>
      <el-table-column prop="videoCover" label="视频封面"> </el-table-column>
      <el-table-column label="操作" width="200">
        <el-button type="primary" @click="editDialogVisible = true"
          >编辑</el-button
        >
        <el-button type="danger">删除</el-button>
      </el-table-column>
    </el-table>
    <!-- 分页器 -->
    <el-pagination
      background
      layout="prev, pager, next"
      :total="allData.length"
      :current-page.sync="currentPage"
      :page-size.sync="pageSize"
      @current-change="updateData"
      style="position: fixed; bottom: 0; right: 0"
    >
    </el-pagination>
    <!-- 添加视频弹窗 -->
    <el-dialog title="添加视频" :visible.sync="addDialogVisible" width="30%">
      <el-form>
        <el-form-item label="视频标题">
          <el-input v-model="videoTitle" style="width: 340px"></el-input>
        </el-form-item>
        <el-form-item label="视频封面">
          <el-input v-model="videoCover" style="width: 260px"></el-input>
          <el-button style="margin-left: 10px" type="primary">上传</el-button>
        </el-form-item>
        <el-form-item label="视频源文件">
          <el-input v-model="videoUrl" style="width: 250px"></el-input>
          <el-button style="margin-left: 10px" type="primary">上传</el-button>
        </el-form-item>
        <el-form-item label="视频总时长">
          <el-input
            v-model="videoTime"
            :disabled="true"
            style="width: 330px"
          ></el-input>
        </el-form-item>
        <el-form-item label="需要解锁时间">
          <el-input v-model="needUnlockTime" style="width: 320px"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addDialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 编辑视频弹窗 -->
    <el-dialog title="编辑视频" :visible.sync="editDialogVisible" width="30%">
      <el-form>
        <el-form-item label="视频标题">
          <el-input v-model="videoTitle" style="width: 340px"></el-input>
        </el-form-item>
        <el-form-item label="视频封面">
          <el-input v-model="videoCover" style="width: 260px"></el-input>
          <el-button style="margin-left: 10px" type="primary">上传</el-button>
        </el-form-item>
        <el-form-item label="视频源文件">
          <el-input v-model="videoUrl" style="width: 250px"></el-input>
          <el-button style="margin-left: 10px" type="primary">上传</el-button>
        </el-form-item>
        <el-form-item label="视频总时长">
          <el-input
            v-model="videoTime"
            :disabled="true"
            style="width: 330px"
          ></el-input>
        </el-form-item>
        <el-form-item label="需要解锁时间">
          <el-input v-model="needUnlockTime" style="width: 320px"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editDialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { GetVideoList } from "@/api/api";
export default {
  data() {
    return {
      startDate: "",
      endDate: "",
      addDialogVisible: false,
      editDialogVisible: false,
      tableData: [],
      currentPage: 1,
      pageSize: 8,
      displayedData: [],
      allData: [],
      page: 1,
      size: 10,
      total: "",
    };
  },
  created() {
    this.allData = this.tableData;
    this.updateData();
  },
  methods: {
    updateData() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = this.currentPage * this.pageSize;
      this.displayedData = this.allData.slice(start, end);
    },
    GetVideoLists() {
      const token = localStorage.getItem("token");
      GetVideoList(token, this.page, this.size).then((res) => {
        if (res.data.code === 200) {
          this.tableData = res.data.data.data;
          this.total = res.data.data.total;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
  },
};
</script>

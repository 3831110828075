<template>
  <div>
    <el-card style="width: 500px; transform: translateX(100%) translateY(10%)">
      <img src="../assets/logo.png" alt="logo" style="width: 100%" />
      <el-form>
        <el-form-item label="请输入账户">
          <el-input v-model="username" placeholder="请输入账户"></el-input>
        </el-form-item>
        <el-form-item label="请输入密码">
          <el-input
            v-model="password"
            type="password"
            placeholder="请输入密码"
          ></el-input>
        </el-form-item>
        <el-form-item label="请输入谷歌验证码">
          <el-input
            v-model="VerificationCode"
            placeholder="请输入谷歌验证码"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" style="width: 100%" @click="login()"
            >登录</el-button
          >
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import { Login } from "@/api/api";
import { Message } from "element-ui";

export default {
  data() {
    return {
      username: "",
      password: "",
      VerificationCode: "",
    };
  },
  methods: {
    login() {
      Login(this.username, this.password).then((res) => {
        if (res.data.code === 200) {
          Message({
            message: "登录成功",
            type: "success",
          });
          //刷新页面
          localStorage.setItem("isLoggedIn", true);
          localStorage.setItem("token", res.data.data.token); // 存储 token
          localStorage.setItem("username", res.data.data.username); //存储用户名
          localStorage.setItem("avatar", res.data.data.avatar); //存储用户头像
          this.$router.push("/");
        } else {
          // this.$message.error(res.data.msg);
          Message.error(res.data.msg);
        }
      });
    },
  },
};
</script>
